import {PlatformBuilder} from '@100-m/hauru/src/utils/platformBuilder'
import BaseApp from '@100-m/hauru/src/App.vue'
import BaseModule from '@100-m/hauru/src/module.js'
import Cedrus from './module.js'
import '@100-m/hauru/src/assets/css/platform.css'
import '@100-m/hauru/src/assets/css/tailwind.css'
import './index.css'
import auth0Login from '@100-m/hauru/src/features/auth/auth0-login.js'

const authConfig = auth0Login({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  project: import.meta.env.VITE_PROJECT,
  urls: ['https://fireauth.100m.io/'],
})

Cedrus.globalMixin = {
  watch: {
    '$root.db.config.translation': {
      handler() {
        $root.translations.cedrus = {
          en: { ...$root.translations.cedrus.en, ...$root.db.config.translation.en },
          fr: { ...$root.translations.cedrus.fr, ...$root.db.config.translation.fr },
        }
      },
      deep: true,
    },
  },
}
const baseMixin = {
  computed: {
    domains() {
      if (!this.dates || !this.dates.length) return
      const first_date = new Date(this.dates.first())
      const last_date = new Date(this.dates.last())
      const domains = {}
      domains.ytd = [last_date.start('year'), last_date]
      domains.mtd = [last_date.start('month'), last_date]
      if (last_date.minus('1 month') > first_date) domains['1m'] = [last_date.minus('1 month'), last_date]
      if (last_date.minus('1 year') > first_date) domains['1y'] = [last_date.minus('1 year'), last_date]
      if (last_date.minus('3 years') > first_date) domains['3y'] = [last_date.minus('3 years'), last_date]
      if (last_date.minus('5 years') > first_date) domains['5y'] = [last_date.minus('5 years'), last_date]
      domains.inception = [first_date, last_date]
      return domains
    },
    domain() {
      // []
      try {
        // TODO Manage case with no datasets (kiid dci)
        const find_domain = closing => {
          return { Q1: 'XXXX-01|XXXX-03', Q2: 'XXXX-04|XXXX-06', Q3: 'XXXX-07|XXXX-09', Q4: 'XXXX-10|XXXX-12' }[closing.slice(5, 7)].replace(
            /XXXX/g,
            closing.slice(0, 4),
          )
        }
        let d =
          (this.$route.query.domain &&
            (this.$route.query.domain.includes('Q') ? find_domain(this.$route.query.domain).split('|') : this.$route.query.domain.split('|'))) ||
          []
        const dates = this.x[this.screen.datasets[0]].dates.__.keys()
        const first_date = dates.first()
        const last_date = dates.last()
        if (d.length > 0 && !this.$route.query.domain.includes('Q')) {
          const fmt = d[0].length <= 7 ? 'Y-MM' : 'Y-MM-D'
          const fd = new Date(dates.first())
          const d1 = new Date(d[0]) < fd ? fd.format() : d[0]
          const d2 = new Date(d[1]) <= fd ? fd.plus('2 month').format(fmt) : d[1]
          update_query({ domain: '' + d1 + (d[1] ? '|' + d2 : '') })
        }
        if (d.length === 0 && this.config.datasets[this.screen.datasets[0]].period === 'inception') return [first_date, last_date]
        if (d.length === 0) d = [last_date.slice(0, 4)]
        if (d.unique().length === 1 && d[0].length === 4) {
          let last_date_year_minus_1 = new Date(d[0])
          last_date_year_minus_1.setFullYear(last_date_year_minus_1.getFullYear() - 1)
          d = [last_date_year_minus_1.end('year').format(), [new Date(d[0]).end('year').format(), last_date].min()]
        }
        if (d.unique().length === 1 && d[0].length === 7)
          d = [new Date(d[0]).start('month').format(), [new Date(d[0]).end('month').format(), last_date].min()]
        if (d[0].length === 7) d[0] = new Date(d[0]).start('month').format()
        if (d.length === 1) d = [new Date(d[0]).start('month').format(), d[0]]
        if (d[1].length === 7) d[1] = [new Date(d[1]).end('month').format(), last_date].min()
        if (d[0] < first_date) d[0] = first_date
        if (d[0] > last_date) d[0] = last_date
        if (d.unique().length === 2 && d[1] < first_date) d[1] = first_date
        if (d.unique().length === 2 && d[1] > last_date) d[1] = last_date

        if (!dates.includes(d[0])) {
          d[0] = dates.__.filter(p => p < d[0]).last() || d[1]
        }
        if (!dates.includes(d[1])) {
          d[1] = dates.__.filter(p => p < d[1]).last()
        }
        if (this.config.datasets[this.screen.datasets[0]].period === 'daily' && !this.$route.query.evolution) return [d[1], d[1]]
        return d
      } catch (e) {
        return [new Date().minus('1 month').format(), new Date().format()]
      }
    },
  },
}
BaseApp.mixins.push(baseMixin)
const platform = (new PlatformBuilder())
  .setBaseApp(BaseApp)
  .addApp(BaseModule)
  .addApp(Cedrus)
  .setAuth(authConfig)
  .setRouterMode('hash')
  .build()
platform.mount('main')
$root.db = { x: 1 }
$root.translations.cedrus_original = JSON.parse(JSON.stringify($root.translations.cedrus))
idb.clear()
