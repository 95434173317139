import { ref } from 'vue'

/**
 * Tooltip feature composable for chartjs components
 * @return {{tooltipOptions: {external: function(*): void, enabled: boolean}, tooltip: ToRef<{shown: boolean, title: string, rows: []}>}}
 */
export default function () {
  const tooltip = ref({
    shown: false,
    title: '',
    rows: [],
  })
  const tooltipOptions = {
    enabled: false,
    external: context => {
      tooltip.value.shown = context.tooltip.opacity !== 0
      tooltip.value.title = context.tooltip.title[0]
      tooltip.value.rows = context.tooltip.dataPoints.map(p => {
        return {
          value: p.raw._data,
          label: p.label,
          color: Array.isArray(p.dataset.backgroundColor) ? p.dataset.backgroundColor[p.dataIndex] : p.dataset.backgroundColor,
          borderColor: Array.isArray(p.dataset.borderColor) ? p.dataset.borderColor[p.dataIndex] : p.dataset.borderColor,
        }
      })
    },
  }
  return {
    tooltip,
    tooltipOptions,
  }
}
