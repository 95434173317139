<style>
.line-chart {}
</style>

<template lang="pug">
.line-chart
  .line-inner
    <svg viewBox="0 0 600 100" fill="none">
      <defs>
        <linearGradient id="linear" x1="0" x2="0" y1="0" y2="100" gradientUnits="userSpaceOnUse">
          <stop stop-color="#2A4371" stop-opacity="0.5"/>
          <stop stop-color="#2A4371" stop-opacity="0" offset="1"/>
        </linearGradient>
      </defs>
      <path d="M1,60L40,90L60,1L600,1" stroke="#2A4371" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1,60L40,90L60,1L600,1L600,100L60,100L40,100L1,100Z" fill="url(#linear)"/>
      <path d="M1,50L30,80L50,10L600,10" stroke="#E7553C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="741" height="124" viewBox="0 0 741 124" fill="none">
      <path d="M1 7L16.074 19.5039L30.3621 1L37.7166 19.5039L49.4088 32.8268L58.0836 44.2097L60.7241 58.8576H85.6167L107.492 51.7903L115.036 58.8576L118.43 76.8064L131.254 81.3548L139.308 95L155.392 84.0081L172.365 87.7984L181.417 85.1452L190.469 88.9355L205.39 86.1181L218.002 88.9355L230.395 86.1181V79.4567H242.895L257.185 74.2756L270.428 69.0945L276.84 76.8064L283.252 81.3548L296.453 79.4567L310.765 74.2756L313.048 67.3306L321.481 69.0945L330.411 74.2756L336.055 64.6774L346.238 66.1936L348.124 60.129L356.422 53.5512L367.737 58.6129L372.263 53.5512H378.633L390.744 48.7581L398.665 37.3871L411.488 39.6613L425.069 32.8268L434.495 61.6452L443.478 57.7922H453.731L456.371 32.8268L466.555 45.4094L475.984 39.6613L481.641 50.2742L489.365 47.3673L492.579 42.4488L508.043 37.3871L515.586 42.4488H523.299L527.796 49.3231L535.576 47.3673L540.479 55.5607L546.891 42.4488L555.189 60.8871L558.96 62.7823L562.732 56.7177L565.372 50.2742L570.275 42.4488L577.441 45.4094L582.344 53.5512L587.595 56.5118L594.791 53.5512L598.94 61.6929L610.813 53.5512L625.101 61.6929L635.902 56.7177L638.919 43.8306H646.463L652.12 48.7581L655.515 37.7661L662.304 32.0806L665.698 37.7661L675.109 42.4488L682.671 48.7581L691.184 45.4094L697.003 42.4488L700.02 37.0081L705.678 38.9032L711.713 34.3548H717.747L722.65 38.9032L728.232 41.4923L733.41 43.6615L736.234 47.2769L740 53.7846" stroke="#2A4371" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.074 19.5039L1 7V124H740V53.7846L736.234 47.2769L733.41 43.6615L728.232 41.4923L722.65 38.9032L717.747 34.3548H711.713L705.678 38.9032L700.02 37.0081L697.003 42.4488L691.184 45.4094L682.671 48.7581L675.109 42.4488L665.698 37.7661L662.304 32.0806L655.515 37.7661L652.12 48.7581L646.463 43.8306H638.919L635.902 56.7177L625.101 61.6929L610.813 53.5512L598.94 61.6929L594.791 53.5512L587.595 56.5118L582.344 53.5512L577.441 45.4094L570.275 42.4488L565.372 50.2742L562.732 56.7177L558.96 62.7823L555.189 60.8871L546.891 42.4488L540.479 55.5607L535.576 47.3673L527.796 49.3231L523.299 42.4488H515.586L508.043 37.3871L492.579 42.4488L489.365 47.3673L481.641 50.2742L475.984 39.6613L466.555 45.4094L456.371 32.8268L453.731 57.7922H443.478L434.495 61.6452L425.069 32.8268L411.488 39.6613L398.665 37.3871L390.744 48.7581L378.633 53.5512H372.263L367.737 58.6129L356.422 53.5512L348.124 60.129L346.238 66.1936L336.055 64.6774L330.411 74.2756L321.481 69.0945L313.048 67.3306L310.765 74.2756L296.453 79.4567L283.252 81.3548L276.84 76.8064L270.428 69.0945L257.185 74.2756L242.895 79.4567H230.395V86.1181L218.002 88.9355L205.39 86.1181L190.469 88.9355L181.417 85.1452L172.365 87.7984L155.392 84.0081L139.308 95L131.254 81.3548L118.43 76.8064L115.036 58.8576L107.492 51.7903L85.6167 58.8576H60.7241L58.0836 44.2097L49.4088 32.8268L37.7166 19.5039L30.3621 1L16.074 19.5039Z" fill="url(#paint0_linear)"/>
      <path d="M1 15L17.0847 21.3216L21.3394 35.8796L25.6333 50.2818L39.419 38.2735L49.5887 32.5553L61.7646 37.4766L68.4215 44.9907L77.4613 47.3102L88.0076 50.2818L92.5275 43.4722L100.814 42.8481L106.464 41.5741L111.737 37.4766H123.037L133.206 33.6019L138.48 36.6389L143.753 35.8796L153.169 40.0556L156.936 47.3102L164.846 52.9293H174.358L190.082 56L197.615 52.9293H206.527L219.084 46.1296L231.548 52.9293L237.917 51.4444L240.615 37.093L249.217 40.0556L258.356 46.1296L268.803 43.8519L279.362 50.2132L290.272 42.8481L296.997 33.6019L311.972 47.3102L322.726 56L331.631 47.3102L340.567 44.8518L360.707 47.3102L369.747 37.4766H379.885L395.359 40.0556L409.296 41.1944L417.205 35.5L423.985 26.0093L433.778 24.4907L439.921 40.0556L455.474 47.6443L466.171 51.4444L475.964 44.8518L483.497 42.3333L489.9 47.6443L499.317 44.8518L501.2 40.0556L508.356 35.5L510.24 29.0463L521.916 22.213L524.648 32.2087L538.489 31.7924L546.775 38.4652L553.932 37.4766L563.966 38.8814L576.154 43.4722L588.987 38.8814L599.884 42.3333L604.78 38.4652L612.22 37.4766L626.518 41.3398L633.03 37.4766L641.316 38.8814L646.966 44.8518H653.746L654.499 36.6389L656.006 29.0463L663.915 31.7924L676.559 32.2087L692.644 33.6135L699.321 38.8814L703.464 44.8518H715.894L721.544 43.4722L727.57 38.8814L733.597 38.4652L740 44.8518" stroke="#E7553C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="16 16"/>
      <defs>
      <linearGradient id="paint0_linear" x1="370.5" y1="1" x2="370.5" y2="124" gradientUnits="userSpaceOnUse">
      <stop stop-color="#2A4371" stop-opacity="0.5"/>
      <stop offset="1" stop-color="#2A4371" stop-opacity="0"/>
      </linearGradient>
      </defs>
    </svg>
</template>

<script>
export const additions = {"mixins":"{\n  data() {\n    return `\n    https://observablehq.com/@d3/d3-line\n    \"M10,60L40,90L60,10L190,10\"\n    d3.line()([[10, 60], [40, 90], [60, 10], [190, 10]])\n    \"M10,60L40,90L60,10L190,10L190,0L60,0L40,0L10,0Z\"\n    d3.area()([[10, 60], [40, 90], [60, 10], [190, 10]])\n    `\n  }\n}\n"}
export default {}
</script>