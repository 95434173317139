<template>
  <svg>
    <circle :cx="8" :cy="8" :r="6" :fill="color" :stroke="borderColor" stroke-width="2" :opacity="1" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    borderColor: {
      type: String,
      default: 'transparent',
    },
  },
}
</script>
<style scoped>
svg {
  width: 16px;
  height: 16px;
}
</style>
