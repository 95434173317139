<template lang="pug">
.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  canvas(ref="canvas")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useTooltip from './composables/useTooltip'
import useOptions from './composables/useOptions'
import useDatasets from './composables/useDatasets'
import useCreateChart from './composables/useCreateChart'
import useCustomLegend from './composables/useCustomLegend'
import legends from '../legend/legends.vue'

export default {
  components: {
    legends,
  },
  props: {
    /**
     * Array of data eg. [{date: '2020-01-01', fund: '105', benchmark: '102'}, ...]
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { data, options } = toRefs(props)

    const { tooltip, tooltipOptions } = useTooltip()
    const { legends } = useCustomLegend()
    const { chartOptions } = useOptions(options, { tooltip: tooltipOptions, legends })
    const { datasets, labels } = useDatasets(data, options)
    const { canvas } = useCreateChart({ type: 'line', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
      legends,
    }
  },
}
</script>
