<template lang="pug">
template(v-if="($root.db || {}).__.keys().length")
  .m100-progress(v-if="progress")
    .progress-bar(:style="{ width: (progress * 100) + '%' }")
  nav-top
  section(:class="$root.screen.theme ? 'pdf' : 'page'")
    router-view(v-if="(!$root.screen.datasets || $root.x)")
  nav-bottom
  toast(:messages="messages")
</template>
<script>
export default {
  inject: ['progress'],
  computed: {
    messages() {
      return this.$root.messages
    },
  },
}
</script>
