import { genColors } from '../../utils'

/**
 * Handle default colors for dx charts
 * @return {{getColor: (function(*=, *=, *=): string|string[])}}
 */
export default function () {
  /**
   * Get one or all available colors
   * @param i The color number (0-11)
   * @param multicolor If true, return all the colors
   * @param transparentize If true, add an alpha on the color
   * @return {string|string[]} One or a list of rgba colors
   */
  const getColor = (i, multicolor = false, transparentize = false) => genColors(i, multicolor, transparentize)

  return {
    getColor,
  }
}
