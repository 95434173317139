<template lang="pug">
.block_content
  h2 {{label}} 
  .value {{format('.2%')(value)}}
.block_content
  .benchmark {{benchmark_label}} 
  .benchmark {{benchmark_value}}
.block_content
  .benchmark {{optional_label}} 
  .benchmark {{optional_value}}
  </template>

<script>
export default {
  name: 'kip-element',
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
      default: '0',
    },
    benchmark_label: {
      type: String,
    },
    benchmark_value: {
      type: String,
    },
    optional_label: {
      type: String,
    },
    optional_value: {
      type: [Number, String]
    }
  },
}
</script>

<style>
.kips_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.kips_wrapper .block {
  flex: 1;
  margin-right: 1%;
  height: fit-content;
  justify-content: center;
  height: 90px;
}
@media only screen and (min-width: 769px) {
  .kips_wrapper .block {
    flex: 1;
    margin-right: 1%;
    height: fit-content;
    justify-content: center;
    height: 90px;
    margin-bottom: 1%;
  }
}

@media only screen and (max-width: 769px) {
  .kips_wrapper .block {
    flex: 1;
    margin-right: 1%;
    height: fit-content;
    justify-content: center;
    height: 90px;
    margin-bottom: 1%;
  }
}
.block_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.benchmark {
  color: var(--secondary);
  padding-top: 6px;
  font-weight: 400;
  font-size: 14px;
}
/* .label {
  color: rgb(53, 53, 53);
  font-weight: 400;
  line-height: 1;
  font-size: 18px;
} */
.value {
  color: var(--primary);
  font-weight: 700;
  font-size: 20px;
}
.benchmark_value {
  color: var(--secondary);
  font-weight: 400;
  font-size: 12px;
}
.space_between {
  justify-content: space-between;
}
.no_benchmark {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.width_benchmark {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.block h2 {
  margin-bottom: 0 !important;
}
</style>
