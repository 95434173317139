<script>
import { h, compile } from 'vue'
export default {
  props: ['screen'],
  computed: {
    component() {
      const { template, style, mixin, mixins } = $root.screen
      return {
        mixins: [appRoutes[1].children[9].component, eval(`(${mixin} || {})`)],
        computed: {
          style() {
            return '<style>' + style + '</style>'
          },
        },
        template: `<div v-html="style"></div><section class="pdf">${template}</section>`,
      }
    },
  },
  render() {
    return h(this.component)
  },
}
</script>
