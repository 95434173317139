import { useRouter, useRoute } from 'vue-router'
import { evaluatePattern, translatePattern } from './translation/translation.js'
import { useTranslation } from './translation'

export default {
  setup() {
    return {
      ...useRouter(),
      ...useRoute(),
      ...useTranslation(),
    }
  },
  async mounted() {
    if (import.meta.env.VITE_DB === 'fs') {
      const database = await (await fetch('/dist/database.json')).json()
      this.db = database
    }
    if (this.$route.path.startsWith('/doc')) this.$root.retract = false
  },
  data() {
    return {
      messages: [],
      db: {},
      project: import.meta.env.VITE_PROJECT,
      slide: 0,
      retract_filters: localStorage.retract_filters === 'true',
      retract_period: localStorage.retract_period !== 'false',
      retract_user: localStorage.retract_user !== 'false',
      retract: window.innerWidth < 900 || localStorage.retract === 'true',
      // translations: window.translations,
      lang: localStorage.LANG || 'en',
      size: '',
      browser: '',
    }
  },
  computed: {
    // t() {
    //   return this.translations[this.lang] || {}
    // },
    screen() {
      const app = this.$route.meta.app
      const path = this.$route.params.screen || this.$route.meta.name || this.$route.path.replace('/' + app, '').slice(1) || 'slash'
      if (this.$route.meta.builder) return { path, ...this.$route.meta, ...$root.db?.builder?.[path], ...$('.builder')?.__vueParentComponent.data.undo.last() }
      return { path, ...this.$route.meta }
    },
    app() {
      return this.$route?.meta.app
    },
    appath() {
      return this.app ? '/' + this.app + '/' : '/'
    },
    profile() {
      return this.$auth.profile
    },
    cssvar() {
      const defaultVars = {
        logo: 'url(icon.png)',
        icon: 'url(icon.png)',
        nav: 'url(icon.png)',
      }
      return this.$cssvar || defaultVars
    },
    config() {
      const _config = this.app ? this.$config.apps[this.app].config : this.$config
      const pages = this.$router.getRoutes()
      const screens = pages
        .__.filter(v => v.meta.app === this.app)
        .__.reduce((acc, v) => {
          const path = v.path.split('/').last() || 'slash'
          acc[path] = { ...v.meta, path }
          return acc
        }, {})
      return {
        screens,
        //translation: translations,
        ..._config,
      }
    },
    screens() {
      // []
      const config = this.config
      return config?.userflow?.__.map(screen => config.screens[screen] || { path: screen }) || []
    },
    dates() {
      try {
        return this.x[this.screen.datasets[0]].dates.__.keys()
      } catch (e) {
        return []
      }
    },
    domain() {
      // []
      try {
        // TODO Manage case with no datasets (kiid dci)
        const find_domain = closing => {
          return { Q1: 'XXXX-01|XXXX-03', Q2: 'XXXX-04|XXXX-06', Q3: 'XXXX-07|XXXX-09', Q4: 'XXXX-10|XXXX-12' }[closing.slice(5, 7)].replace(
            /XXXX/g,
            closing.slice(0, 4),
          )
        }
        let d =
          (this.$route.query.domain &&
            (this.$route.query.domain.includes('Q') ? find_domain(this.$route.query.domain).split('|') : this.$route.query.domain.split('|'))) ||
          []
        const dates = this.x[this.screen.datasets[0]].dates.__.keys()
        const first_date = dates.first()
        const last_date = dates.last()
        if (d.length > 0 && !this.$route.query.domain.includes('Q')) {
          const fmt = d[0].length <= 7 ? 'Y-MM' : 'Y-MM-D'
          const fd = new Date(dates.first())
          const d1 = new Date(d[0]) < fd ? fd.format() : d[0]
          const d2 = new Date(d[1]) <= fd ? fd.plus('2 month').format(fmt) : d[1]
          update_query({ domain: '' + d1 + (d[1] ? '|' + d2 : '') })
        }
        if (d.length === 0 && this.config.datasets[this.screen.datasets[0]].period === 'inception') return [first_date, last_date]
        if (d.length === 0) d = [last_date.slice(0, 4)]
        if (d.unique().length === 1 && d[0].length === 4)
          d = [new Date(d[0]).start('year').minus('1 day').format(), [new Date(d[0]).end('year').format(), last_date].min()]
        if (d.unique().length === 1 && d[0].length === 7)
          d = [new Date(d[0]).start('month').minus('1 day').format(), [new Date(d[0]).end('month').format(), last_date].min()]
        if (d[0].length === 7) d[0] = new Date(d[0]).start('month').minus('1 day').format()
        if (d.length === 1) d = [new Date(d[0]).start('month').minus('1 day').format(), d[0]]
        if (d[1].length === 7) d[1] = [new Date(d[1]).end('month').format(), last_date].min()
        if (d[0] < first_date) d[0] = first_date
        if (d[0] > last_date) d[0] = last_date
        if (d.unique().length === 2 && d[1] < first_date) d[1] = first_date
        if (d.unique().length === 2 && d[1] > last_date) d[1] = last_date

        if (!dates.includes(d[0])) {
          d[0] = dates.__.filter(p => p < d[0]).last() || d[1]
        }
        if (!dates.includes(d[1])) {
          d[1] = dates.__.filter(p => p < d[1]).last()
        }
        if (this.config.datasets[this.screen.datasets[0]].period === 'daily' && !this.$route.query.evolution) return [d[1], d[1]]
        return d
      } catch (e) {
        return [new Date().minus('1 month').format(), new Date().format()]
      }
    },
    userflow() {
      // {}
      try {
        const u = this.$route.params.userflow.split('-')[0]
        const db = this.asofdb || this.db
        if (db[['userflows', $root.app].join('-')]) return this.db[['userflows', $root.app].join('-')][u]
        return db.userflows[u]
      } catch (e) {
        return {}
      }
    },
    benchmark() {
      return (this.share && this.share.benchmark) || (this.userflow && this.userflow.benchmark)
    },
    share() {
      try {
        const u = this.$route.params.userflow.split('-')[0]
        const shareIsin = this.$route.params.userflow.split('-')[1]
        const db = this.asofdb || this.db
        return db.userflows[u].shares[shareIsin]
      } catch (e) {
        return {}
      }
    },
    period() {
      try {
        const days = (new Date(this.domain[1]) - new Date(this.domain[0])) / 86400000
        if (days >= 4 && days <= 7) return ['weekly', new Date(this.domain[1]).format('%Y-W%W', this.lang.split('_')[0])]
        if ((days >= 28 && days <= 31) || (/[0-9]{4}-[0-9]{2}/.test(this.$route.query.domain) && days < 35))
          return ['monthly', new Date(this.domain[1]).format('day, month, year', this.lang.split('_')[0])]
        if ((days >= 89 && days <= 92) || (/[0-9]{4}-[0-9]{2}/.test(this.$route.query.domain) && days < 95))
          return ['quarterly', new Date(this.domain[1]).format('day, month, year', this.lang.split('_')[0])]
        if (days >= 181 && days <= 185) return ['biannual', new Date(this.domain[1]).format('day, month, year', this.lang.split('_')[0])]
        if (days >= 363 && days <= 366) return ['annual', new Date(this.domain[1]).format('day, month, year', this.lang.split('_')[0])]
        return [
          'custom',
          this.t.from +
            ' ' +
            new Date(this.domain[0]).format('day, mon, year', this.lang.split('_')[0]) +
            ' ' +
            this.t.to +
            ' ' +
            new Date(this.domain[1]).format('day, mon, year', this.lang.split('_')[0]),
        ]
      } catch (e) {
        return []
      }
    },
    domains() {
      if (!this.dates || !this.dates.length) return
      const first_date = new Date(this.dates.first())
      const last_date = new Date(this.dates.last())
      const domains = {}
      domains.ytd = [last_date.minus('1 year').end('year'), last_date]
      domains.mtd = [last_date.minus('1 month').end('month'), last_date]
      if (last_date.minus('1 month') > first_date) domains['1m'] = [last_date.minus('1 month'), last_date]
      if (last_date.minus('1 year') > first_date) domains['1y'] = [last_date.minus('1 year'), last_date]
      if (last_date.minus('3 years') > first_date) domains['3y'] = [last_date.minus('3 years'), last_date]
      if (last_date.minus('5 years') > first_date) domains['5y'] = [last_date.minus('5 years'), last_date]
      domains.inception = [first_date, last_date]
      return domains
    },
    filters() {
      // {}
      try {
        const dimensions = (this.userflow.dimensions || []).concat(this.screen.dimensions || []).concat(this.share?.dimensions_pdf || [])
        const filters = this.$route.query.__.filter((v, k) => !dimensions.length || dimensions.includes(k)).__.map(v => (typeof v === 'string' ? v.split('|') : v))
        filters.domain = this.domain
        dimensions.__.map(dim => (filters[dim] = filters[dim]))
        return filters
      } catch (e) {
        return {}
      }
    },
    active_filters() {
      return (
        this.filters &&
        this.filters
          .__.filter((v, k) => v && k !== 'domain')
          .__.v()
          .sum('length')
      )
    },
    params() {
      return this.$route.params
    },
    query() {
      return { ...this.$route.query }
    },
    // TODO useContext composable
    context() {
      return { params: this.$route.params, $axios: this.$axios, $route: this.$route, query: this.$route.query }
    },
  },
  methods: {
    translatePattern,
    evaluatePattern,
    toast(message) {
      if ($root.messages.__.find(message)) return
      message.clear = () => {
        clearInterval(message.timeout_id)
        $root.messages = $root.messages.__.filter(d => d.timeout_id !== message.timeout_id)
      }
      if (message.timeout) {
        message.elapsed = message.progress = 0
        message.pause = () => clearInterval(message.timeout_id)
        message.resume = () => {
          message.timeout_id = setInterval(() => {
            message.elapsed += 10
            message.progress = message.elapsed / message.timeout * 100
            if (message.elapsed >= message.timeout) message.clear()
            $root.messages = [].concat($root.messages) // HACK
          }, 10)
        }
        message.resume()
      }
      $root.messages.push(message)
      return message.clear
    },
  },
  created() {
    if (this.$root === this) window.$root = this
    const splash = $('.splashscreen')
    if (splash) {
      splash.style.opacity = 0
      setTimeout(() => splash.remove(), 500)
    }

    const mix = function (c1, c2, p = 0.5) {
      const [r1, g1, b1] = d3.color(c1).toString().slice(4, -1).split(', ').__.map(Number)
      const [r2, g2, b2] = d3.color(c2).toString().slice(4, -1).split(', ').__.map(Number)
      const rgb = [Math.round((1 - p) * r1 + p * r2), Math.round((1 - p) * g1 + p * g2), Math.round((1 - p) * b1 + p * b2)].join(', ')
      return d3.color(`rgb(${rgb})`).toString().hex()
    }
    const primary = getComputedStyle(document.documentElement).getPropertyValue('--colors-primary-default') || '#000000'
    document.documentElement.style.setProperty('--colors-primary-light', mix(primary, 'white', 0.9))
    document.documentElement.style.setProperty('--colors-primary-dark', mix(primary, 'black', 0.15))

    const context = () => {
      $root.size = size()
      $root.browser = browser()
      localStorage.retract_filters = $root.retract_filters
      localStorage.retract_period = $root.retract_period
      localStorage.retract_user = $root.retract_user
      localStorage.retract = $root.retract
      localStorage.LANG = $root.lang
      const app = $root.$route?.meta?.app || 'default'
      // TODO better replace for app name (only if it starts with it)
      const path =
        $root.$route.fullPath
          .split('?')
          .first()
          .replace('/' + app, '')
          .replace(/\//g, '-')
          .replace(/:/g, '')
          .slice(1) || 'slash'
      const name = $root.$route?.meta?.name
      $('main').className = [
        // 'path' + this.$route.matched.last().path.replace(/\//g, '-').replace(/:/g, ''),
        Object.entries(this.$route.query)
          .__.filter(([k]) => k !== 'domain')
          .__.reduce((acc, [k, v]) => acc.push([k, v].join('-')) && acc, []),
        // this.$route.params.__.reduce((acc, v, k) => acc.push([k,v].join('-')) && acc, []),
        // this.domain ? this.domain.unique().length === 1 ? 'single-date' : 'multi-date' : 'no-date',
        // this.screen.path === 'slash' && 'screen-slash',
        // this.screen.theme && 'screen-pdf',
        // this.userflow && this.userflow.name && 'userflow-' + this.userflow.name,
        // this.userflow && this.userflow.category && 'category-' + this.userflow.category,
        $root.benchmark && 'benchmark-' + $root.benchmark,
        // this.app,
        path,
        'path-' + path,
        'screen-' + path,
        'app-' + app,
        this.screen && this.screen.theme ? 'screen-pdf' : '',
        this.screen && this.screen.theme ? ['pdf'] : [`screen-${name}`, name],
        localStorage.PROJECT,
        window !== window.top && 'iframe',
        browser(),
        size(),
        $root.profile && 'authenticated',
        $root.retract && 'menu-retract',
        !$root.retract_user && 'menu-user',
      ]
        .flat()
        .__.filter()
        .join(' ')
    }
    // window.addEventListener('popstate', context, { passive: true })
    // window.addEventListener('hashchange', context, { passive: true })
    window.addEventListener('resize', () => requestAnimationFrame(context), { passive: true })
    this.$watch(context, () => null, { immediate: true })
  },
}
