<template lang="pug">
dx-chart.chart(
  v-if="data"
  :data-source="data" 
  @point-hover-changed="tooltip"
  @series-hover-changed="onSeriesHover"
  :customize-point="options.customizePoint"
)
  dx-series(
    v-for="plot in options.plots"
    :name="$root.t[plot.name] || plot.name || $root.t[plot.valueField] || plot.valueField" 
    :key="plot.valueField" 
    :value-field="plot.valueField" 
    :argument-field="plot.argumentField || options.argumentField"
    :color="plot.color"
    :type="plot.type || options.type"
    hoverMode="allArgumentPoints")

  //- dx-common-series-settings(type="bar" argument-field="key")
  //- dx-series(type="bar" :corner-radius="4" name="test" key="key" value-field="value" hoverMode="allArgumentPoints")
  //- dx-series(name="benchmark" key="benchmark" value-field="benchmark")
  //- dx-margin(:bottom="20")
  dx-argument-axis
    dx-label(overlapping-behavior="stagger")
  //-   //- dx-tick-interval(:months="1")
  //-   dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
  //- dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
  dx-legend(:visible="!!options.legend" vertical-alignment="bottom" horizontal-alignment="center" item-text-position="right" items-alignment="left" hover-mode="excludePoints")
    dx-font(family="var(--font-family)")
  //- dxexport(:enabled="true")
  //- dxtitle(text="Energy Consumption in 2004")
  //-   dxsubtitle(text="(Millions of Tons, Oil Equivalent)")
  //- dx-tooltip(:enabled="true" :opacity="0")
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
} from 'devextreme-vue/chart'
import RoundedMarker from './dx-rounded-marker.vue'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    RoundedMarker,
    // MarkerTemplate,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({
        argumentType: 'datetime',
        argumentField: 'date',
      }),
    },
    type: {
      type: String,
      default: 'line',
    },
  },
  emits: ['tooltip', 'onSeriesHover'],
  methods: {
    tooltip(ev) {
      const point = ev.target
      if (point.isHovered()) {
        this.$emit('tooltip', point?.data)
        this.$emit('onSeriesHover', point?.series.getValueFields()[0])
      } else {
        this.$emit('tooltip', null)
        this.$emit('onSeriesHover', null)
      }
    },
    onSeriesHover(ev) {
      const series = ev.target
      if (series.isHovered()) {
        const key = ev?.target?.getValueFields()[0]
        this.$emit('onSeriesHover', key)
      } else {
        this.$emit('onSeriesHover', null)
      }
    },
  },
  // return {
  //   dataSource: service.getCountriesInfo(),
  //   energySources: service.getEnergySources(),
  //   types: ['line', 'stackedline', 'fullstackedline'],
  //   type: 'line'
  // };
  // }
}
</script>
<style scoped>
/* .chart {
  height: 100%;
} */
</style>
