<template lang="pug">
.relative.chart
  dx-chart(
    v-if="data"
    :data-source="data"
    @point-hover-changed="tooltip"
    @series-hover-changed="onSeriesHover"
    @customize-point="metadata.customizePoint"
  )
    dx-common-series-settings(:type="metadata.type || 'line'" :argument-field="metadata.argumentField" :argument-type="metadata.argumentType")
      dx-point(hoverMode="allArgumentPoints" :size="8")
    dx-series(
      v-for="plot in metadata.plots"
      :name="$root.t[plot.name] || plot.name || $root.t[plot.valueField] || plot.valueField"
      :key="plot.valueField"
      :value-field="plot.valueField"
      :color="plot.color"
      hoverMode="allArgumentPoints")
    dx-margin(:bottom="20")
    dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels" :argument-type="metadata.argumentType")
      dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
    dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
    dx-legend(vertical-alignment="top" horizontal-alignment="left" orientation="horizontal" item-text-position="right" items-alignment="left" hover-mode="excludePoints" marker-template="markerTemplate")
      dx-font(family="var(--font-family)")
    template(#markerTemplate="{ data }")
      rounded-marker(:item="data")
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
} from 'devextreme-vue/chart'
import RoundedMarker from './dx-rounded-marker.vue'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    RoundedMarker,
    // MarkerTemplate,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    metadata: {
      type: Object,
      default: () => ({
        argumentType: 'datetime',
        argumentField: 'date',
      }),
    },
    type: {
      type: String,
      default: 'line',
    },
  },
  emits: ['tooltip', 'onSeriesHover'],
  methods: {
    tooltip(ev) {
      const point = ev.target
      if (point.isHovered()) {
        this.$emit('tooltip', point?.data)
        this.$emit('onSeriesHover', point?.series.getValueFields()[0])
      } else {
        this.$emit('tooltip', null)
        this.$emit('onSeriesHover', null)
      }
    },
    onSeriesHover(ev) {
      const series = ev.target
      if (series.isHovered()) {
        const key = ev?.target?.getValueFields()[0]
        this.$emit('onSeriesHover', key)
      } else {
        this.$emit('onSeriesHover', null)
      }
    },
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
