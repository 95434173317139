<template>
  <svg>
    <circle :cx="6" :cy="6" :r="6" :fill="color" :opacity="1" />
  </svg>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      color: this.item.series.isVisible() ? this.item.marker.fill : '#888',
    }
  },
}
</script>
