<template lang="pug">
.pdf-page
  pdf-header
  h1.uppercase.text-4xl.font-normal.h-24.mx-5 {{t[$root.userflow.name] || $root.userflow.name}}
  .row
    .column.expand
      brick
        .kpis_card.flex.justify-between.mt-4
          ul.box_one
            //- TODO: Add the global date format method
            li.text-white.font-bold {{ t.period }}: 
              span.font-light( v-if="$root.lang==='en'").text-white {{ t.from }} {{ new Date($root.domain[0]).format('year, mon, day', $root.lang) }} {{t.to}} {{ new Date($root.domain[1]).format('year, mon, day', $root.lang) }}
              span.font-light(v-else).text-white  {{ t.from }} {{ new Date($root.domain[0]).format('day', $root.lang) }} {{ new Date($root.domain[0]).format('mon, year', $root.lang).format('month', $root.lang).capitalize(true) }} {{t.to}} {{ new Date($root.domain[1]).format('day', $root.lang)}} {{ new Date($root.domain[1]).format('mon,year', $root.lang).format('month',$root.lang).capitalize(true) }}
            
            li.text-white.font-bold {{ t.financial_product }}: 
              span.font-light.text-white  {{format( '.3s'+$root.userflow.currency)(gainLoss)}}
            li.text-white.font-bold {{t.performance}}:
              span.font-light.text-white {{format('.2%')(getPerformance)}}
            li.text-white.font-bold {{t.volatility}}: 
              span.font-light.text-white {{format('.2%')(getVolatility_fund)}}
            li.text-primary.medium.font-bold.text-white {{t.report_contact}}:
            li.text-primary.font-light.text-white {{t[$root.userflow.contact_email] || $root.userflow.contact_email}}
          ul.box_two
            li.text-white.font-bold {{t.aum}}: 
              span.font-light.text-white  {{format( '.3s'+$root.userflow.currency)(getAum)}}
            li.text-white.font-bold {{t.benchmark}}: 
              span.font-light.text-white  
              span.font-light.text-white {{t[$root.userflow.benchmark] || $root.userflow.benchmark || 'NA' }}
            li.text-white.font-bold {{t.performance_benchmark}}: 
              span.font-light.text-white  
              span.font-light.text-white {{format('.2%')(getBenchmark)}}
            li.text-white.font-bold {{t.volatility_benchmark}}: 
              span.font-light.text-white  {{format('.2%')(getVolatility_benchmark)}}
  pdf-footer(:page-number="1")
.pdf-page.mt-4(v-for="(item, index) in table_pdf" :key="index") 
  pdf-header
  brick
    table
      thead
        tr
          th
          th.text-gray-500
            div {{t.liquidity}}
          th.text-gray-500
            div {{t.allocation}}
          th.text-gray-500
            div {{t.position}}
          th.text-gray-500
            div {{t.aum_last_year_raw}}
          th.text-gray-500
            div {{t.returns_last_month}}
          th.text-gray-500
            div {{t.benchmark_last_month}}
          th.text-gray-500
            div {{t.returns}}
          th.text-gray-500
            div {{t.benchmark}}
          th.text-gray-500
            div {{t.contribution}}
          th.text-gray-500
            div {{t.pnl}}
          th.text-gray-500
            div {{t.volatility_yearly}}
          th.text-gray-500
            div {{t.pnl_realised_raw}}
          th.text-gray-500
            div {{t.pnl_latent_raw}}
      tbody
        tr
          td.text-gray-500
            div {{t[item.group]}}
            div {{item.manager}}
          td.text-primary.font-medium
            div {{!item.liquidity ? 0 : +item.liquidity}}
          td.text-primary.font-medium
            div {{!item.allocation ? 0 : format('.2%')(item.allocation)}}
          td.text-primary.font-medium
            div {{!item.position ? 0 : format( '.3s'+$root.userflow.currency)(item.position)}}
          td.text-primary.font-medium
            div {{!item.aum_last_year_raw ? 0 : format( '.3s'+$root.userflow.currency)(item.aum_last_year_raw)}}
          td.text-primary.font-medium
            div {{!item.returns_last_month ? 0 : format('.2%')(item.returns_last_month)}}
          td.text-primary.font-medium
            div {{!item.benchmark_last_month ? 0 : format('.2%')(item.benchmark_last_month)}}
          td.text-primary.font-medium
            div {{!item.returns ? 0 : format('.2%')(item.returns)}}
          td.text-primary.font-medium
            div {{!item.benchmark ? 0 : format('.2%')(item.benchmark)}}
          td.text-primary.font-medium
            div {{!item.contribution ? 0 : format('bp')(item.contribution)}}
          td.text-primary.font-medium
            div {{!item.pnl ? 0 : format( '.3s'+$root.userflow.currency)(item.pnl)}}
          td.text-primary.font-medium
            div {{!item.volatility_yearly ? 0 : format('.2%')(item.volatility_yearly)}}
          td.text-primary.font-medium
            div {{!item.pnl_realised_raw ? 0 : format( '.3s'+$root.userflow.currency)(item.pnl_realised_raw)}}
          td.text-primary.font-medium
            div {{!item.pnl_latent_raw ? 0 : format( '.3s'+$root.userflow.currency)(item.pnl_latent_raw)}}
        tr(v-for="(itemData, index) in item.data" :key="index")
          td
            .text-gray-500 {{itemData.name}}
            .text-primary.font-medium {{itemData.manager}}
          td.text-primary.font-medium
            div {{!itemData.liquidity ? 0 : +itemData.liquidity}}
          td.text-primary.font-medium
            div {{!itemData.allocation ? 0 : format('.2%')(itemData.allocation)}}
          td.text-primary.font-medium
            div {{!itemData.position ? 0 : format( '.3s'+$root.userflow.currency)(itemData.position)}}
          td.text-primary.font-medium
            div {{!itemData.aum_last_year_raw ? 0 : format( '.3s'+$root.userflow.currency)(itemData.aum_last_year_raw)}}
          td.text-primary.font-medium
            div {{!itemData.returns_last_month ? 0 : format('.2%')(itemData.returns_last_month)}}
          td.text-primary.font-medium
            div {{!itemData.benchmark_last_month ? 0 : format('.2%')(itemData.benchmark_last_month)}}
          td.text-primary.font-medium
            div {{!itemData.returns ? 0 : format('.2%')(itemData.returns)}}
          td.text-primary.font-medium
            div {{!itemData.benchmark ? 0 : format('.2%')(itemData.benchmark)}}
          td.text-primary.font-medium
            div {{!itemData.contribution ? 0 : format('bp')(itemData.contribution)}}
          td.text-primary.font-medium
            div {{!itemData.pnl ? 0 : format( '.3s'+$root.userflow.currency)(itemData.pnl)}}
          td.text-primary.font-medium
            div {{!itemData.volatility_yearly ? 0 : format('.2%')(itemData.volatility_yearly)}}
          td.text-primary.font-medium
            div {{!itemData.pnl_realised_raw ? 0 : format( '.3s'+$root.userflow.currency)(itemData.pnl_realised_raw)}}
          td.text-primary.font-medium
            div {{!itemData.pnl_latent_raw ? 0 : format( '.3s'+$root.userflow.currency)(itemData.pnl_latent_raw)}}
  pdf-footer(:page-number="index + 2")
.pdf-page
  pdf-header
  h1.uppercase {{t[$root.userflow.name] || $root.userflow.name}}
  .block_canvas__pdf
    h2 {{t.returns}}
    cjs-bar-chart(:data="rolling_returns"  :options="pdfReturnsOptions")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatPercentRows(tooltip.rows)")
  .block_canvas__pdf
    h2 {{t.performance}}
    cjs-line-chart(:data="performance_historical" :options="performanceOptions")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatPercentRows(tooltip.rows)" )
  .block_canvas__pdf
    h2 {{t.aum}}
    cjs-timeseries-chart(:data="aum_evolution"  :options="aumOptions")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")
  pdf-footer(:page-number="table.length + 2")
.pdf-page
  pdf-header
  h1.uppercase {{t[$root.userflow.name] || $root.userflow.name}}
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.at}} {{$root.domain[1]}}
    cjs-donut-chart(:data="pie_allocation" :options="allocationsOptions.type")
      template(v-slot:legend="{legends}")
          valued-legends(:legends="formatNumberLegends(legends)")
      template(v-slot:tooltip="{tooltip}")
        donut-tooltip(v-if="!tooltip.shown" :title="tooltip.title" :rows="showNumberAum(tooltip.rows)" )
        donut-tooltip(v-else="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)" :chartArea="tooltip.chartArea")    
  .block_canvas__pdf
    .row
      h2 {{t.cumulated_liquidity_pdf}} {{ t.at }} {{ $root.domain[1] }}
    cjs-bar-chart(:data="cumulated_liquidy" :options="cumulatedOptions")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")
  .block_canvas__pdf
    h2 {{t.contribution}}
    cjs-bar-chart(:data="contribution_graph"  :options="contributionOptions")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.itle" :rows="formatPercentRows(tooltip.rows)")
  pdf-footer(:page-number="table.length + 3")
.pdf-page
  pdf-header
  h1.uppercase {{t[$root.userflow.name] || $root.userflow.name}}
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.at }} {{$root.domain[1]}}
    cjs-bar-chart(:data="allocations.type" :options="allocationsOptions.type")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.by }} {{ t.devise || 'devise' }}
    cjs-bar-chart(:data="allocations.devise" :options="allocationsOptions.devise")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.by }} {{ t.zone_geo || 'zone_geo' }}
    cjs-bar-chart(:data="allocations.zone_geo" :options="allocationsOptions.zone_geo" @series-click="update_filter('zone_geo', $root.t.__.find(v => v === $event) || $event)")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  pdf-footer(:page-number="table.length + 2")
.pdf-page
  pdf-header
  h1.uppercase {{t[$root.userflow.name] || $root.userflow.name}}
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.by }} {{ t.secteur || 'secteur' }}
    cjs-bar-chart(:data="allocations.secteur" :options="allocationsOptions.secteur" @series-click="update_filter('secteur', $root.t.__.find(v => v === $event) || $event)")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  .block_canvas__pdf
    h2 {{t.allocation}} {{ t.by }} {{ t.label_isr || 'isr' }}
    cjs-bar-chart(:data="allocations.isr" :options="allocationsOptions.isr" @series-click="update_filter('isr', $root.t.__.find(v => v === $event) || $event)")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  pdf-footer(:page-number="table.length + 3")
</template>

<script>
import useKpis from '../../composables/useKpis'
import usePerformance from '../../composables/usePerformance'
import useDetails from '../../composables/useDetails'
import * as common from '../../composables/common'
export default {
  theme: '.pdf-page\n  brick(data="block.header")\n  brick.expand\n  brick(data="block.footer")\n',
  datasets: ['allocation', 'contribution', 'performance', 'mapping', 'trades'],
  setup() {
    return {
      ...useKpis(),
      ...useDetails(),
      ...usePerformance(),
      ...common,
    }
  },
}
</script>
<style>
.pdf-page table {
  background: var(--white);
  box-shadow: none !important;
}
.pdf-page :is(th, tr, td) {
  padding: 4px 8px;
}
.pdf-page table thead {
  height: 80px;
}
.pdf-page table thead th {
  position: relative;
}
.pdf-page table thead th div {
  position: absolute;
  white-space: pre-line;
  width: 100px;
  left: -10px;
  bottom: 40px;
  transform: rotate(-50deg);
}
.pdf-page table tbody tr:nth-child(1) {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--primary);
  font-weight: 500;
}
.pdf-page table tbody tr:nth-child(1) td {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--primary);
  box-shadow: var(--box-shadow);
  color: var(--white);
  width: 100%;
}
.pdf-page tbody th:nth-child(n + 2),
.pdf-page tbody tr:nth-child(n + 2),
.pdf-page tbody td:nth-child(n + 2) {
  min-width: 40px;
  max-width: 40px;
  padding: 0;
  text-align: center;
}
.block_canvas__pdf {
  display: flex;
  flex-direction: column;
  background: rgb(255 255 255);
}
.pdf-page canvas {
  max-height: 240px !important;
}
.pdf-page .block {
  margin: 0 !important;
}
.pdf-page .donut-wrapper .legend {
  width: auto;
  margin: 0 20px;
}
</style>
