import { computed } from 'vue'
import useGraphs from './usePerformance'

export default function useKpis() {
  const first = computed(() => {
    return new Date($root.domain.first()) - new Date($root.x.contribution.first().date) > 0 ? $root.domain.first() : $root.x.contribution.first().date
  })

  const last = computed(() => {
    return new Date($root.domain.last()) - new Date($root.x.contribution.last().date) < 0 ? $root.domain.last() : $root.x.contribution.last().date
  })
  const getPerformance = computed(() => {
    const contribution = $root.xf.contribution.data.filter(d => d.override =="False")
    return contribution.sum('pnl_wallet_shares') / $root.xf.performance.data.first().nav_before
  })
  const getBenchmark = computed(() => {
    return (
      $root.xf.performance.data.__.filter(d => d.benchmark != 0).last().benchmark /
        $root.xf.performance.data.__.filter(d => d.benchmark != 0).first().benchmark -
      1
    )
  })
  const gainLoss = computed(() => {
    return $root.xf.contribution.data.sum('pnl')
  })
  const getAum = computed(() => {
    return $root.xf.contribution.date.__.v().last().position
  })

  const weekly_data = computed(() => {
    // get fund weekly (same as in getVolatility_fund)
    const now = new Date($root.domain[1])
    const last_year = new Date($root.domain[1]).minus('1 year').format()
    const raw_data = $root.x.contribution.filter(d => d.date >= last_year && (d.position !== 0 || d.delta !== 0)).group('date')
    const filtered_liq = raw_data.__.map(d => d.filter(e => e.override == 'True'))
    const sum_filtered_liq = filtered_liq.__.map((current, date, index) => {
      if (index == 0) return { date: current.date, sum_delta_liq: 0 }
      return { date: date, sum_delta_liq: current.sum('position') - filtered_liq.__.v()[index - 1].sum('position') }
    })
    const sum_list = raw_data.__.map((current, date, index) => ({
      date: date,
      sum_position: current.sum('position'),
      sum_delta_others: current.filter(d => d.override == 'False').sum('delta'),
    }))
    var vl_day = 100
    var perf_day = 0
    const perf_list = sum_list.__.map((current, date, index) => {
      if (index == 0) return { date: current.date, performance: 0, vl: vl_day }
      perf_day = (current.sum_position - current.sum_delta_others - sum_filtered_liq.__.v()[index].sum_delta_liq) / sum_list.__.v()[index - 1].sum_position - 1
      vl_day *= 1 + perf_day
      return { date: current.date, performance: perf_day, vl: vl_day }
    })
    return perf_list.__.filter(d => d.date >= last_year && d.date <= $root.domain[1] && new Date(d.date).getDay() === now.getDay()).__.map(d => ({
      fund: d.vl,
      benchmark: $root.x.performance.find(p => p.date === d.date)?.benchmark,
    }))
  })

  const getTrackingError = computed(() => {
    const filtered_arr = Object.values(weekly_data.value)
    if (!filtered_arr) return 0
    const method = ($root.config.analytics && $root.config.analytics.tracking_error_log) || 'log'
    const methods = {
      log: (d, i) => Math.log(d.fund / filtered_arr[i - 1].fund) - Math.log(d[metric] / filtered_arr[i - 1][metric]),
      'no-log': (d, i) => d.fund / filtered_arr[i - 1].fund - d[metric] / filtered_arr[i - 1][metric],
    }
    const metric = 'benchmark'
    return (
      filtered_arr
        .map((d, i) => (i === 0 ? null : methods[method](d, i)))
        .filter(d => d !== null)
        .std(1) * Math.sqrt(52) || 0
    )
  })

  const getVarX = computed(() => {
    const filtered_arr = Object.values(weekly_data.value)
    if (!filtered_arr) return 0
    const method = ($root.config.analytics && $root.config.analytics.tracking_error_log) || 'log'
    const methods = {
      log: (d, i) => Math.log(d.fund / filtered_arr[i - 1].fund),
      'no-log': (d, i) => d.fund / filtered_arr[i - 1].fund,
    }
    const x = 0.95
    return filtered_arr
      .map((d, i) => (i === 0 ? null : methods[method](d, i)))
      .filter(d => d !== null)
      .percentile(x)
  })

  const getInformationRatio = computed(() => {
    const perf_data = useGraphs().performance_historical.value
    const rebase = 100
    const arr = perf_data.map((d, index) => {
      if (index == 0) return { date: d.key, fund: rebase, benchmark: rebase }
      return { date: d.key, fund: rebase * (1 + d.fund), benchmark: rebase * (1 + d.benchmark) }
    })
    const metric = 'benchmark'
    const annualized_perf_fund = arr.performance_annualized('fund') || arr.performance('fund')
    const annualized_perf_benchmark = arr.performance_annualized(metric) || arr.performance(metric)
    const real_years = Math.round(((new Date(arr.last().date) - new Date(arr.first().date)) / (365 * 24 * 60 * 60 * 1000)) * 100) / 100
    const information_ratio = real_years >= 1 ? (annualized_perf_fund - annualized_perf_benchmark) / getTrackingError.value : '-'
    return information_ratio
  })

  const getVolatility_fund = computed(() => {
    return (
      Object.values(weekly_data.value)
        .map('fund')
        .map((v, i, ds) => (i === 0 ? 0 : v / ds[i - 1] - 1))
        .slice(1)
        .std(1) * Math.sqrt(52)
    )
  })

  const getVolatility_benchmark = computed(() => {
    return (
      Object.values(weekly_data.value)
        .map('benchmark')
        .map((v, i, ds) => (i === 0 ? 0 : v / ds[i - 1] - 1))
        .slice(1)
        .std(1) * Math.sqrt(52)
    )
  })

  const rebaseVL = computed(() => {
    const perf_data = useGraphs().performance_historical.value
    const rebase = 100
    const vl_series = perf_data.map((d, index) => {
      if (index == 0) return { date: d.key, vl: rebase }
      return { date: d.key, vl: rebase * (1 + d.fund) }
    })
    return vl_series
  })
  const getDrawdown = computed(() => {
    return rebaseVL.value.drawdown_details('vl')?.['drawdown']
  })
  const getMaxYield = computed(() => {
    return rebaseVL.value.maxgain_details('vl')?.['maxgain']
  })
  const getRecoveryDuration = computed(() => {
    const duration = rebaseVL.value.drawdown_details('vl')?.['duration']
    return duration != '-' ? duration + ' ' + $root.t['days'] : $root.t['not_achieved']
  })
  const getRecoveryPeriod = computed(() => {
    const drawdown = rebaseVL.value.drawdown_details('vl')
    if (!drawdown?.['begin']) return '-'
    return $root.t['from'] + ' ' + drawdown?.['begin'] + ' ' + $root.t['to'] + ' ' + drawdown?.['end']
  })
  const getMaxDrawdown = isin => {
    if ($root.x.mapping.find(d => d.isin == isin)?.override == 'True') return 0
    return $root.xf.contribution.data
      .group('isin')
      [isin].map(d => d.price)
      .max_drawdown_value()
  }
  return {
    first,
    last,
    getPerformance,
    getBenchmark,
    gainLoss,
    getAum,
    getVolatility_fund,
    getVolatility_benchmark,
    getDrawdown,
    getMaxYield,
    getRecoveryDuration,
    getRecoveryPeriod,
    getMaxDrawdown,
    getTrackingError,
    getVarX,
    getInformationRatio,
  }
}
