/**
 * Emit event when series are hovered
 * @param emit Vue emit
 * @return {{handleSeriesHover: handleSeriesHover}}
 */
export default function (emit) {
  const handleSeriesHover = ev => {
    const series = ev.target
    if (series.isHovered()) {
      const key = ev?.target?.getValueFields()[0]
      emit('seriesHover', key)
    } else {
      emit('seriesHover', null)
    }
  }
  return {
    handleSeriesHover,
  }
}
