<template lang="pug">
header
  .row.items-center.h-auto
    h1.headline.uppercase {{ t[fund] || fund }} - {{ t[page] || page }}
    span.contact_email {{ benchmark }}
    button.button.ml-4
      router-link(:to="'/' + $root.userflow.name + '/pdf?domain=' + ($root.query.domain || '')" target="_blank") PDF
    button.button.ml-4(v-if="$root.screen.name === 'performance'" @click="export_perf_csv()") {{ t.performance }}
    button.button.ml-4(v-if="$root.query.__.keys().length" @click="$root.query.map((d, k) => update_query({ [k]: null }))") Reset
    a.contact_email.ml-auto( ref="emailTo" :href="`mailto:${emailValue}`" target='_newtab') {{ email}}
</template>

<script>
export default {
  props: {
    page: { type: String },
    fund: { type: String },
    benchmark: { type: String },
    email: { type: String },
  },
  computed: {
    emailValue() {
      const name = $root.userflow.contact || ''
      const NameAndEmail = $root.userflow.contact_email || ''
      return NameAndEmail.substring(name.length).trim()
    },
  },
  methods: {
    export_perf_csv() {
      // Strange date behavior on cedrus perf is compute with nav before of $root.domain[0] 
      const navBefore = $root.x.performance.dates.__.keys().filter(d => d <= $root.domain[0]).last()
      const perfs = $root.x.performance.filter(d => d.date >= navBefore && d.date <= $root.domain[1])
      const firstNav = perfs[0]
      return perfs.map(d => {
        const date = d.date
        const fund = (d.nav / firstNav.nav) * 100 //rebased to 100
        const benchmark = (d.benchmark / firstNav.benchmark) * 100 //rebased to 100
        return { date, fund, benchmark }
      }).dlCSV()
    }
  }
}
</script>
