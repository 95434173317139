const donutColors = ['#006d8d', '#114655', '#57b693', '#21a7b3', '#007b80', '#a1de66', '#7eeb89', '#55dec1', '#92e5f7']
const barThickness = ((x, y = 12) => x - (x % y))(window.innerWidth / 3 / 12)
const performanceOptionsDatasets = [
  {
    key: 'fund',
    label: 'Performance',
    borderWidth: '2',
    backgroundColor: '#57b693',
    borderColor: '#57b693',
    datalabels: true,
  },
  {
    key: 'benchmark',
    label: 'Benchmark',
    backgroundColor: '#073a3b',
    borderWidth: '1',
    borderColor: '#073a3b',
    datalabels: true,
  },
]
const performanceKpiOptionsDatasets = [
  {
    key: 'min',
    label: 'Min',
    backgroundColor: '#939597',
    borderWidth: '1',
    borderColor: '#939597',
    datalabels: true,
  },
  {
    key: 'avg',
    label: 'Avg',
    backgroundColor: '#53bab2',
    borderWidth: '1',
    borderColor: '#53bab2',
    datalabels: true,
  },
  {
    key: 'max',
    label: 'Max',
    backgroundColor: '#cbff5e',
    borderWidth: '1',
    borderColor: '#cbff5e',
    datalabels: true,
  },
]

export const returnsOptions = {
  labelKey: 'key',
  datasets: [{ key: 'value', label: 'Fund', borderColor: 'none', borderRadius: '3', barThickness: '15' }],
  datalabels: true,
  labelFormatter: value => format('bp')(value),
  formatY: value => format('bp')(value),
}
export const pdfReturnsOptions = {
  labelKey: 'key',
  datasets: [{ key: 'value', label: 'Fund', borderColor: 'none', borderRadius: '3', barThickness: '15' }],
  datalabels: true,
  labelFormatter: value => format('.1%')(value),
  formatY: value => format('.1%')(value),
}

export const returnsPDFOptions = {
  labelKey: 'key',
  datasets: [{ key: 'value', label: 'Fund', borderColor: 'none', borderRadius: '3', barThickness: '40' }],
  datalabels: true,
  labelFormatter: value => format('.2%')(value),
  formatY: value => format('.1%')(value),
}
export const performanceOptions = {
  labelKey: 'key',
  datasets: [...performanceOptionsDatasets],
  formatY: value => format('.1%')(value),
}

export const performanceKpiOptions = {
  labelKey: 'key',
  datasets: [...performanceOptionsDatasets, ...performanceKpiOptionsDatasets],
  formatY: value => format('.1%')(value),
}
export const contributionOptions = {
  labelKey: 'key',
  datasets: [{ key: 'value', label: 'Fund', backgroundColor: donutColors, borderColor: 'none', borderRadius: '3', barThickness, scales: {} }],
  datalabels: true,
  labelFormatter: value => format('.2%')(value),
  formatY: value => format('.1%')(value),
}

export const formatRows = rows => {
  return rows.map(r => {
    return {
      label: $root.t[r.label] || r.label,
      value: r.value,
      color: r.color,
    }
  })
}
export const formatMillionRows = rows => {
  return rows.map(r => {
    return {
      label: $root.t[r.label] || r.label,
      value: format('.3s' + $root.userflow.currency)(r.value),
      color: r.color,
    }
  })
}
export const formatPercentRows = rows => {
  return rows.map(r => {
    return {
      label: $root.t[r.label] || r.label,
      value: format('.2%')(r.value),
      color: r.color,
    }
  })
}
export const formatPerformancePercentRows = rows => {
  return rows
    .filter(r => ['Performance', 'Benchmark'].includes(r.label))
    .map(r => {
      return {
        label: $root.t[r.label] || r.label,
        value: format('.2%')(r.value),
        color: r.color,
      }
    })
}

export const formatPercentDonutChart = rows => {
  const total_sum = Object.values($root.xf.allocation.type).sum()
  return rows.map(r => {
    return {
      label: $root.t[r.label] || r.label,
      value: format('.2%')(r.value / total_sum),
      color: r.color,
    }
  })
}
export const formatPerformanceLegends = legends => {
  return legends
    .filter(l => ['Performance', 'Benchmark'].includes(l.label))
    .map(l => {
      return {
        ...l,
      }
    })
}
export const formatPerformanceKpiLegends = (legends, data) => {
  return legends
    .filter(l => ['Min', 'Max', 'Avg'].includes(l.label))
    .map(l => {
      return {
        ...l,
        data: ' (' + format('.2%')(data[0][l.label.toLowerCase()]) + ')  ',
      }
    })
}
export const formatPercentLegends = legends => {
  const total_sum = legends.map(l => l.data).sum()
  return legends.map(l => {
    return {
      ...l,
      data: format('.2%')(l.data / total_sum),
    }
  })
}
export const formatNumberLegends = legends => {
  return legends.map(l => {
    return {
      ...l,
      data: format('.3s' + $root.userflow.currency)(l.data),
    }
  })
}
export const showPercentAum = () => {
  const total_sum = Object.values($root.xf.allocation.type).sum()
  return [
    {
      label: $root.t.aum,
      value: format('.2%')($root.xf.contribution.date.__.v().last().position / total_sum),
      color: 'var(--cat0)',
    },
  ]
}

export const showNumberAum = rows => {
  if (rows.length == 0) {
    return [
      {
        label: $root.t.aum,
        value: format('.3s' + $root.userflow.currency)($root.xf.contribution.date.__.v().last().position),
        color: 'var(--cat0)',
      },
    ]
  } else {
    return rows.map(r => {
      return {
        label: $root.t.aum,
        value: format('.3s' + $root.userflow.currency)($root.xf.contribution.date.__.v().last().position),
        color: 'var(--cat0)',
      }
    })
  }
}

export const formatTitle = title => {
  return title
}

// export const riskOptions = {
//   default: () => ({}),
//   datasets: [
//     {
//       label: 'Annualized returns',
//       key: 'serie-1',
//       backgroundColor: donutColors,
//       radius: '20',
//       fill: true,
//       datalabels: true,
//       backgroundColor: '#57b693',
//     },
//     {
//       label: 'volatility',
//       backgroundColor: '#114655',
//     },
//   ],

//   formatX: value => format('.1')(value) + 'bp',
//   formatY: value => format('.1')(value) + 'bp',
// }

export const riskOptions = {
  labelKey: 'label',
  datasets: [
    {
      key: 'serie-1',
      label: 'Annualized returns',
      backgroundColor: '#57b693',
      radius: '50',
      borderRadius: '30',
    },
    {
      label: 'volatility',
      scale: '40',
    },
  ],
  datalabels: true,
  formatX: value => format('.2')(value) + 'bp',
  formatY: value => format('.2')(value) + 'bp',
}

export const kpiAvg = (arr, selector) => {
  if (!arr || !selector) return ''
  return arr.__.map(selector).__.reduce((acc, el, _, arr) => (acc += el / arr.length), 0)
}

export const kpiMin = (arr, selector) => {
  if (!arr || !selector) return ''
  return arr.__.map(selector).__.min()
}

export const kpiMax = (arr, selector) => {
  if (!arr || !selector) return ''
  return arr.__.map(selector).__.max()
}
