<template lang="pug">
.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  canvas(ref="canvas")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useTooltip from './composables/useTooltip'
import useOptions from './composables/useOptions'
import useDatasets from './composables/useDatasets'
import useCreateChart from './composables/useCreateChart'
import useCustomLegend from './composables/useCustomLegend'

export default {
  props: {
    /**
     * Array of data eg. [{date: '2020-01-01', fund: '105', benchmark: '102'}, ...]
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['seriesClick'],
  setup(props, {emit}) {
    const { data, options } = toRefs(props)

    const { tooltip, tooltipOptions } = useTooltip()
    const { legends } = useCustomLegend()
    const { chartOptions } = useOptions(options, { tooltip: tooltipOptions, legends })
    chartOptions.onClick = e => {
      const activePoints = e.chart.getElementsAtEventForMode(e, 'nearest', {}, false)
      const [{ index }] = activePoints
      emit('seriesClick', e.chart.data.labels[index])
    }
    const { datasets, labels } = useDatasets(data, options)
    const { canvas } = useCreateChart({ type: 'bar', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
      legends,
    }
  },
}
</script>
