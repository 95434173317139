<template lang="pug">
.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  .relative.chart
    dx-pie-chart(
      :data-source="data"
      @point-hover-changed="handleTooltip"
      @series-hover-changed="handleSeriesHover"
      @customize-point="options.customizePoint"
      @point-click="handleSeriesClick"
      :palette='palette'
      type='doughnut'
      :innerRadius="0.75"
      startAngle=90
      @drawn="handleDrawn"
    )
      dx-series(
        v-for="(dataset, i) in options.datasets"
        :name="dataset.label"
        :key="dataset.key"
        :argument-field="options.labelKey"
        :value-field="dataset.key"
        hoverMode="allArgumentPoints")
      dx-legend(:visible="false")
      template(#markerTemplate="{ data }")
        legend-marker(:color="data.marker.fill")
    slot(name="tooltip" :tooltip="tooltip")
      donut-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows' :chartArea='tooltip.chartArea')
</template>
<script>
import DxPieChart, {
  DxSeries,
  DxCommonSeriesSettings,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxLabel,
  DxFont,
} from 'devextreme-vue/pie-chart'
import { toRefs } from 'vue'
import useSeriesHover from './composables/useSeriesHover'
import usePieTooltip from './composables/usePieTooltip'
import usePalette from './composables/usePalette'
import LegendMarker from '../legend/legend-marker.vue'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxPieChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxLabel,
    DxFont,
    LegendMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover', 'seriesClick'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = usePieTooltip(options, emit)
    const { handleSeriesHover } = useSeriesHover(emit)
    const { palette } = usePalette()
    const { legends, handleDrawn } = useCustomLegends()
    const handleSeriesClick = (ev) => {
      emit('seriesClick', ev)
    }

    return {
      tooltip,
      handleTooltip,
      palette,
      handleSeriesHover,
      handleSeriesClick,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
