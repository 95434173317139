<template lang="pug">
.relative
  canvas(ref="canvas")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useCreateChart from './composables/useCreateChart'
import useWorldMapDatasets from './composables/useWorldMapDatasets'
import useWorldmapTooltip from './composables/useWorldmapTooltip'

export default {
  props: {
    /**
     * Array of data eg. [{date: '2020-01-01', fund: '105', benchmark: '102'}, ...]
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { data, options } = toRefs(props)
    const { tooltip, tooltipOptions } = useWorldmapTooltip()
    const chartOptions = {
      showOutline: true,
      showGraticule: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipOptions,
      },
      scales: {
        xy: {
          projection: 'equalEarth',
        },
        color: {
          ticks: {
            callback: value => (options.value.formatY ? options.value.formatY(value) : value),
          },
        },
      },
    }
    const { datasets, labels } = useWorldMapDatasets(data, options, {}, { multicolor: true })
    const { canvas } = useCreateChart({ type: 'choropleth', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
    }
  },
}
</script>
