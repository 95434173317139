<template lang="pug">
.relative
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  canvas(ref="canvas")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import { toRefs } from 'vue'
import useCreateChart from './composables/useCreateChart'
import useXYDatasets from './composables/useXYDatasets'
import useXYOptions from './composables/useXYOptions'
import useXYTooltip from './composables/useXYTooltip'
import useCustomLegend from './composables/useCustomLegend'

export default {
  props: {
    /**
     *  Data object with 1 or more keys that contains an Array of {x,y} points. eg {data1: [{x: 10, y15}, ...], data2: [...]}
     */
    data: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Metadata
     */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { data, options } = toRefs(props)

    const { tooltip, tooltipOptions } = useXYTooltip()
    const { legends } = useCustomLegend()
    const { chartOptions } = useXYOptions(options, { tooltip: tooltipOptions, legends })
    const { datasets, labels } = useXYDatasets(data, options)
    const { canvas } = useCreateChart({ type: 'bubble', datasets, labels, chartOptions })

    return {
      canvas,
      tooltip,
      legends,
    }
  },
}
</script>
