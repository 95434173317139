<template lang="pug">
.relative.chart
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  dx-chart(
      :data-source="data"
      @point-hover-changed="handleTooltip"
      @series-hover-changed="handleSeriesHover"
      @customize-point="options.customizePoint"
      @drawn="handleDrawn"
    )
      dx-common-series-settings(:type="mode" :argumentField="options.labelKey")
        dx-point(:visible="true" hoverMode="allArgumentPoints" :size="8")
      dx-series(
        v-for="(dataset, i) in options.datasets"
        :name="dataset.label"
        :key="dataset.key"
        :value-field="dataset.key"
        :color="dataset.backgroundColor || getColor(i)"
        hoverMode="allArgumentPoints")
      dx-margin(:bottom="20")
      dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels")
        dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
      dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
      dx-legend(:visible="false")
      template(#markerTemplate="{ data }")
        legend-marker(:color="data.marker.fill")

  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
} from 'devextreme-vue/chart'
import RoundedMarker from './dx-rounded-marker.vue'
import { genColors } from '../utils'
import useTooltip from './composables/useTooltip'
import { computed, toRefs } from 'vue'
import useColors from './composables/useColors'
import useSeriesHover from './composables/useSeriesHover'
import LegendMarker from '../legend/legend-marker.vue'
import useCustomLegends from './composables/useCustomLegends'
export default {
  components: {
    LegendMarker,
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    RoundedMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({ mode: 'area' }),
    },
  },
  emits: ['tooltip', 'seriesHover'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useTooltip(options, emit)
    const { getColor } = useColors()
    const { handleSeriesHover } = useSeriesHover(emit)
    const { legends, handleDrawn } = useCustomLegends()
    const mode = computed(() => {
      if (options.value.mode && !['area', 'stackedarea', 'fullstackedarea'].includes(options.value.mode)) {
        throw new Error(`Unknown mode '${options.value.mode}'`)
      }
      return options.value.mode || 'area'
    })
    return {
      tooltip,
      handleTooltip,
      getColor,
      handleSeriesHover,
      mode,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
