import { ref } from 'vue'
import { genColors } from '../../utils'

/**
 * Handle tooltip feature for dx charts
 * @param options Metadata of the chart
 * @param emit Vue emit function
 * @return {{handleTooltip: handleTooltip, tooltip: ToRef<{shown: boolean, titleColor: null, title: string, rows: []}>}}
 */
export default function (options, emit) {
  const tooltip = ref({
    title: '',
    shown: false,
    titleColor: null,
    rows: [],
  })

  /**
   * Tooltip event handler
   * @param ev Dx point hover event
   */
  const handleTooltip = ev => {
    const point = ev.target
    tooltip.value.shown = point.isHovered()
    tooltip.value.title = ev.target.series.name
    tooltip.value.titleColor = ev.target.getColor()
    tooltip.value.rows = [
      {
        label: 'x',
        value: ev.target.argument,
      },
      {
        label: 'y',
        value: ev.target.value,
      },
    ]

    if (point.isHovered()) {
      emit('tooltip', point?.data)
      emit('seriesHover', point?.series.getValueFields()[0])
    } else {
      emit('tooltip', null)
      emit('seriesHover', null)
    }
  }
  return {
    tooltip,
    handleTooltip,
  }
}
