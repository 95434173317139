<template lang="pug">
.tooltip-table.shadow.border-t.absolute.top-0.right-0.bg-white.border-t-primary.pointer-events-none(class="min-w-[180px]")
  div.text-gray-500.border-b.border-b-gray-200(v-if="title")
    .flex.flex-row.items-center.p-1
      .h-2.w-2.rounded-full.border-transparent.mr-2(v-if="titleBorderColor || titleColor" :style="{ background: titleColor, borderColor: titleBorderColor }")
      .text-xs {{ title }}
  div
    div.flex.py-1.p-2.justify-between(v-for="(row, k) in rows" :key="k")
      .flex.flex-row.items-center
        .h-2.w-2.rounded-full.border.border-transparent.mr-2(:style="{ background: row.color, borderColor: row.borderColor }")
        .text-sm {{ row.label }}
      .ml-2
        .font-bold.text-sm {{ row.value }}
</template>
<script>
export default {
  props: {
    /**
     * Tooltip title
     */
    title: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: () => null,
    },
    titleBorderColor: {
      type: String,
      default: () => null,
    },
    /**
     * Tooltip table rows
     * eg of row: {color: 'blue', label: 'Fund', value: '102.2'}
     */
    rows: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
