<template lang="pug">
.drawer-wrapper
  slot(name='button' v-if='button')
    button(btn='' primary='' @click='open = true')
      svg-icon(:name='icon' v-if='icon')
      | {{ button }}
  transition(name='fade')
    .overlay(@click="open = false" v-if='open')
  transition(name='fade')
    svg.close(v-if='open' @click="open = false")
      line(fill='none' stroke='#FFFFFF' stroke-width='2' stroke-miterlimit='10' x1='2' y1='2' x2='18' y2='18')
      line(fill='none' stroke='#FFFFFF' stroke-width='2' stroke-miterlimit='10' x1='18' y1='2' x2='2' y2='18')
  transition(:name="$root.size === 'mobile' ? 'slide-top' : 'slide-left'")
    .drawer(v-if='open')
      slot
</template>

<script>
export default {
  props: ['button', 'icon'],
  data() {
    return {
      open: false,
    }
  },
  mounted() {
    this.ev_keydown = e => {
      if (e.key === 'Escape') this.open = false
    }
    window.addEventListener('keydown', this.ev_keydown)
  },
  detached() {
    window.removeEventListener('keydown', this.ev_keydown)
  },
}
</script>

<style>
.login .drawer-wrapper .drawer { overflow: auto;display: flex;flex-direction: column;align-items: center; color: white;background: var(--colors-text);}
.drawer-wrapper .overlay { z-index: 10000;position: fixed;top: 0;left: 0;min-width: 100vw;min-height: 100vh;background: rgb(0, 0, 0, .5);cursor: pointer; }
.drawer-wrapper .drawer { z-index: 10001;position: fixed;top: 0;left: 0;margin: 10px;padding: 20px;width: calc(100% - 20px);height: calc(100% - 20px);overflow: auto;background: white; }
.drawer-wrapper .close { z-index: 10002;position: fixed;top: 10px;right: 10px;width: 40px;height: 40px;padding: 10px;background: rgb(0, 0, 0, .5);cursor: pointer; }
.drawer-wrapper button .svg-icon { position: relative;top: 4px;width: 16px;height: 16px;fill: white; }
@media (min-width: 600px) { .drawer-wrapper .drawer { margin: 0;padding: 40px;width: 60%;height: 100%; } }
</style>
