<template lang="pug">
header 
  .logo.mb-4
    img(src="https://platform.100m.io/dist/cedrus/logo-large.jpg")
 
  
</template>

<script>
export default {}
</script>

<style scoped>
.logo {
  height: 100%;
  width: 15%;
}
</style>
