<template lang="pug">
template(v-if="$root.db.config")
  page-header(
    :page="t[$root.screen.name] || $root.screen.name"
    :fund="t[$root.userflow.name] || $root.userflow.name"
    :benchmark="t[$root.userflow.benchmark] || $root.userflow.benchmark"
    :email="t[$root.userflow.contact_email] || $root.userflow.contact_email")

  .row.graph_box
    .block
      kpi-box(:label="t.allocation" :value="format('.2%')(allocationPercent)")
    .block
      kpi-box(:label="t.aum" :value="format( '.3s'+$root.userflow.currency)(allocationSum)")

  .row
    .block_canvas
      h2 {{t.allocation}} {{ t.at }} {{$root.domain[1]}}
      cjs-bar-chart(:data="allocations.type" :options="allocationsOptions.type" @series-click="update_filter('type', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")

    .block_canvas
      h2 {{t.allocation}} {{ t.by }} {{ t.devise || 'devise' }}
      cjs-bar-chart(:data="allocations.devise" :options="allocationsOptions.devise" @series-click="update_filter('devise', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")

  .row
    .block_canvas
      //- h2 {{t.allocation}} {{ t.by }} {{ t.liquidity || 'liquidity' }}
      //- cjs-bar-chart(:data="allocations.liquidity" :options="allocationsOptions.liquidity" @series-click="update_filter('liquidity', $root.t.__.find(v => v === $event) || $event)")
      //-   template(v-slot:tooltip="{tooltip}")
      //-     table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
      h2
        a(@click.prevent="handleClickCummulated" v-html="showCumulatedGraph ? t.cumulated_liquidity : t.not_cumulated_liquidity")
        .right_push {{ t.at }} {{ $root.domain[1] }}
      cjs-bar-chart(v-show="showCumulatedGraph" :data="cumulated_liquidy" :options="cumulatedOptions" @series-click="update_filter('liquidity', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")

      cjs-bar-chart(v-show="showUncumulatedGraph" :data="uncumulated_liquidy" :options="cumulatedOptions" @series-click="update_filter('liquidity', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")

    .block_canvas
      h2 {{t.allocation}} {{ t.by }} {{ t.zone_geo || 'zone_geo' }}
      cjs-bar-chart(:data="allocations.zone_geo" :options="allocationsOptions.zone_geo" @series-click="update_filter('zone_geo', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")

  .row.flex.flex-1
    .block_canvas.flex.flex-1
      h2 {{t.allocation}} {{ t.by }} {{ t.secteur || 'secteur' }}
      cjs-bar-chart(:data="allocations.secteur" :options="allocationsOptions.secteur" @series-click="update_filter('secteur', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
  .row.flex.flex-1
    .block_canvas.flex.flex-1
      h2 {{t.allocation}} {{ t.by }} {{ t.label_isr || 'isr' }}
      cjs-bar-chart(:data="allocations.isr" :options="allocationsOptions.isr" @series-click="update_filter('isr', $root.t.__.find(v => v === $event) || $event)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)")
</template>

<script>
import useKpis from '../../composables/useKpis'
import usePerformance from '../../composables/usePerformance'
import * as common from '../../composables/common'
import { ref, computed } from 'vue'

export default {
  icon: 'ic_pie_chart',
  datasets: ['allocation', 'contribution', 'mapping'],
  setup() {
    const showCumulatedGraph = ref(true)
    const showUncumulatedGraph = ref(false)

    function handleClickCummulated() {
      showCumulatedGraph.value = !showCumulatedGraph.value
      showUncumulatedGraph.value = !showUncumulatedGraph.value
    }
    return {
      ...common,
      ...useKpis(),
      ...usePerformance(),
      showCumulatedGraph,
      showUncumulatedGraph,
      handleClickCummulated,
    }
  },
}
</script>
