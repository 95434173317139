import { computed } from 'vue'
import { kpiMin as performanceMin, kpiMax as performanceMax, kpiAvg as performanceAvg } from './common'

const barThickness = ((x, y = 10) => x - (x % y))(window.innerWidth / 3 / 12)

export default function useGraphs() {
  const monthly_performances = computed(function () {
    const last_nav_per_month = $root.x.performance.__.group(d => d.date.slice(0, 7)).__.map(data => data.first().nav_before)
    return $root.xf.contribution.data
      .filter(d => d.override == 'False')
      .__.group(d => d.date.slice(0, 7))
      .__.filter((data, month) => month > $root.domain[0] && month < $root.domain[1] && last_nav_per_month[new Date(month).minus('1 month').format('YYYY-MM')])
      .__.map(data => data.__.map(d => d.pnl_wallet_shares).sum())
      .__.map((data, month) => ({
        fund: data / last_nav_per_month[new Date(month).minus('1 month').format('YYYY-MM')],
      }))
  })
  const performance_historical = computed(() => {
    let data = $root.x.performance.__.filter(d => d.date >= $root.domain[0] && d.date <= $root.domain[1])
    const first = data.first()
    // const data2 = data
    //   .reduce((acc, v) => {
    //     acc[v.date] = {
    //       key: v.date,
    //       fund: +v.nav / +first.nav,
    //       benchmark: +v.benchmark / +first.benchmark,
    //     }
    //     if (!v.benchmark) delete acc[v.date].benchmark
    //     return acc
    //   }, {})
    //   .__.v()
    // debugger
    const perf_by_date = $root.xf.performance.data.group('date')
    let result = $root.xf.contribution.data
      .group('date')
      .__.map((d, date) => {
        return {
          key: date,
          daily_pnl: d
            .filter(d => d.override == 'False')
            .map(e => e.pnl_wallet_shares)
            .sum(),
          fund: 1,
          benchmark: perf_by_date[date].first().benchmark / +first.benchmark,
        }
      })
      .__.v()
      .__.map((d, i, arr) => {
        d.fund = i === 0 ? arr[0].daily_pnl : arr[i - 1].fund + arr[i].daily_pnl
        return { ...d }
      })
      .__.map(d => {
        d.fund = d.fund / $root.xf.performance.data.first().nav_before
        d.benchmark = d.benchmark - 1
        return { ...d }
      })
    const fundKpi = {
      min: performanceMin(result, r => r.fund),
      max: performanceMax(result, r => r.fund),
      avg: performanceAvg(result, r => r.fund),
    }
    //'avg': performanceAvg(result, f => f.fund_id) || 1,
    return result.__.map(d => {
      return { ...fundKpi, ...d }
    })
  })
  const rolling_returns = computed(function () {
    const metric = 'fund'
    const date = new Date($root.xf.performance.data.last().date)
    return (1)
      .upto(12)
      .reduce((acc, num) => {
        const current = date.minus(`${12 - num} month`)
        const key = current.format('YYYY-MM')
        const name = current.format('mon', $root.lang).titleize() + ' ' + current.format('YY')
        if (monthly_performances.value[key]) acc[name] = monthly_performances.value[key][metric]
        return acc
      }, {})
      .__.map((d, k) => ({ key: k, value: d }))
      .__.v()
  })
  const aum_evolution = computed(() => {
    return $root.xf.contribution.date.__.map((d, k) => ({ [k]: d.position }))
      .__.v()
      .map(d => ({ key: Object.keys(d)[0], value: Object.values(d)[0] }))
      .__.filter(d => d.value !== 0)
      .__.v()
  })
  const pie_allocation = computed(() => {
    return $root.xf.allocation.type.__.map((v, k) => ({ key: $root.t[k] || k, value: v }))
      .__.v()
      .sort('-value')
  })
  const allocationTotal = computed(() => {
    return $root.x.allocation
      .filter(v => v.date === $root.domain[1])
      .map('position')
      .sum()
  })
  const allocationSum = computed(() => {
    return $root.xf.allocation.data
      .filter(v => v.date === $root.domain[1])
      .map('position')
      .sum()
  })
  const allocationPercent = computed(() => {
    return allocationSum.value / allocationTotal.value
  })
  const allocationsOptions = computed(() => {
    return $root.xf.allocation.__.filter((v, k) => k !== 'data').__.map((grp, dim) => {
      let donutColors = ['#006d8d', '#114655', '#57b693', '#21a7b3', '#007b80', '#a1de66', '#7eeb89', '#55dec1', '#92e5f7']
      if ($root.filters[dim])
        donutColors = allocations.value[dim].map(
          (v, i) => donutColors[i % donutColors.length] + ($root.filters[dim].map(k => $root.t[k] || k).includes(v.key) ? '' : '40'),
        )
      return {
        labelKey: 'key',
        datasets: [
          {
            key: 'value',
            label: 'Allocation',
            fill: true,
            backgroundColor: donutColors,
            borderRadius: '3',
            barThickness: 40,
            datalabels: true,
          },
        ],
        datalabels: true,
        labelFormatter: v => format('.2s' + $root.userflow.currency)(v),
        formatY: v => format('.3s' + $root.userflow.currency)(v),
      }
    })
  })
  const allocations = computed(() => {
    return $root.xf.allocation.__.filter((v, k) => k !== 'data').__.map((grp, dim) =>
      grp.__.map((v, k) => ({ key: dim === 'secteur' && k != 'liquidity' ? k : $root.t[k] || k, value: v }))
        .__.v()
        .filter('value')
        .sort('-value'),
    )
  })
  const liquidity = computed(() => {
    const get_liquidity_type = (isin, mapping) => {
      const id = mapping.__.find(f => f.isin === isin)
      return +(mapping[id] || {}).liquidity || 'NA'
    }
    return $root.xf.contribution.data
      .filter(d => d.date === $root.domain[1])
      .map(d => {
        return {
          ...d,
          liquidity: get_liquidity_type(d.isin, mapping),
        }
      })
      .__.group(d => d.liquidity)
  })
  const cumulated_liquidy = computed(() => {
    var acc = 0
    let data = liquidity.value.__.map(arr => arr.sum(d => d.position))
    data.__.keys().forEach(d => {
      acc += data[d]
      data[d] = acc
    })
    return data.__.map((d, k) => ({ key: k, value: d })) // TODO Create global method
      .__.v()
      .sort((a, b) => a.key - b.key)
  })
  const uncumulated_liquidy = computed(() => {
    return liquidity.value.__.map(arr => arr.sum(d => d.position))
      .__.map((d, k) => ({ key: k, value: d })) // TODO Create global method
      .__.v()
      .sort((a, b) => a.key - b.key)
  })

  const contribution_graph = computed(() => {
    const vl0 = $root.xf.performance.data.first().nav_before
    const result = $root.xf.contribution.type.__.map(v => v.pnl_wallet_shares / vl0)
    return result.__.map((d, k) => ({ key: $root.t[k] || k, value: d }))
      .__.v()
      .sort('-value')
  })

  const aumOptions = computed(() => {
    return {
      labelKey: 'key',
      datasets: [
        {
          label: $root.t.fund,
          key: 'value',
          borderColor: 'rgb(0 145 153 / 100%)',
          backgroundColor: 'rgb(0 145 153 / 20%)',
          borderWidth: '1.5',
          fill: true,
          datalabels: true,
        },
      ],
      formatY: v => format('.3s' + $root.userflow.currency)(v),
      labelFormatter: v => format('.2s' + $root.userflow.currency)(v),
    }
  })

  const cumulatedOptions = computed(() => {
    return {
      labelKey: 'key',
      datasets: [
        {
          key: 'value',
          label: 'Fund',
          backgroundColor: cumulated_liquidy.value.map((item, index) => {
            return index === cumulated_liquidy.value.length - 1 ? 'rgb(170, 170, 170)' : '#169ba0'
          }),
          borderColor: 'none',
          borderRadius: '3',
          borderWidth: 0,
          barThickness: barChartThickness(cumulated_liquidy.value.length),
          borderAlign: 'center',
        },
      ],
      datalabels: cumulated_liquidy.value.length >= 8 ? false : true,
      formatY: v => format('.3s' + $root.userflow.currency)(v),
      labelFormatter: v => format('.2s' + $root.userflow.currency)(v),
      // sort: key => key,
    }
  })

  const barChartThickness = number_bar => {
    if (number_bar < 10) return 40
    if (number_bar < 15) return 29
    if (number_bar < 20) return 22
    if (number_bar < 25) return 17
    return 13
  }

  return {
    monthly_performances,
    performance_historical,
    rolling_returns,
    aum_evolution,
    pie_allocation,
    allocationSum,
    allocationPercent,
    allocationsOptions,
    allocations,
    liquidity,
    cumulated_liquidy,
    uncumulated_liquidy,
    contribution_graph,
    aumOptions,
    cumulatedOptions,
    barChartThickness,
    // performanceAvg,
    // performanceMin,
    // performanceMax,
  }
}
