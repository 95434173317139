/**
 * Generate chartjs chartOptions according options and chartOptions
 * @param options
 * @param tooltip
 * @param legend
 * @return {{chartOptions: {plugins: {legend: {align: string, labels: {usePointStyle: boolean}}, tooltip: *}, elements: {line: {tension: number}, point: {pointStyle: string, radius: number}}, scales: {x: {grid: {display: boolean}, time: {unit: string}, type: string}, y: {ticks: {callback: function(*=, *, *): (*|undefined)}}}, interaction: {intersect: boolean, axis: string}}}}
 */
export default function (options, { tooltip, legends } = {}) {
  const datalabels =
    options.value.datalabels === true
      ? {
          align: 'top',
          anchor: 'end',
          formatter: options.value.labelFormatter,
        }
      : undefined

  const chartOptions = {
    plugins: {
      datalabels,
      tooltip,
      legend: {
        display: false,
      },
      customLegend: {
        legends,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      bar: {},
      point: {
        pointStyle: 'point',
        radius: 3,
      },
    },
    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'nearest',
    },
    scales: {
      y: {
        ticks: {
          callback: value => options.value.formatY(value),
        },
      },
      x: {
        ticks: {
          callback: value => options.value.formatX(value),
        },
      },
    },
  }
  return {
    chartOptions,
  }
}
