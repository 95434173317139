window.tooltip = document.createElement('div')
tooltip.id = 'tooltip'
document.body.appendChild(tooltip)
addEventListener('mouseover', e => {
  if (tooltip.target && !document.contains(tooltip.target)) tooltip.target.onmouseleave()
  const text = e.target.getAttribute('tt') || e.target.innerText || e.target.value
  if (e.target.hasAttribute('no-tt') || !text || text.includes('\n')) return
  if (!(e.target.getAttribute('tt') && e.target.scrollWidth > e.target.offsetWidth)) return
  tooltip.style = `top: ${e.target.getBoundingClientRect().top}px;left: ${e.target.getBoundingClientRect().left + e.target.offsetWidth / 2}px;text-transform: capitalize;`
  tooltip.innerHTML = text
  tooltip.target = e.target
  e.target.onmouseleave = () => (e.target.onmouseleave = tooltip.target = tooltip.style = tooltip.innerHTML = '')
})
