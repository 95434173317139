<template lang="pug">
.donut-tooltip(class="absolute" :style='style')
  div.text-gray-500.border-b.border-b-gray-200.text-center(v-if="title")
    | {{ title }}
  div
    div.py-1(v-for="(row, k) in rows" :key="k")
      .text-center
        span.number.text-4xl.font-bold(:style="{color: row.color}") {{ row.value }}
      .text-center
        span {{ row.label }}
</template>
<style scoped>

</style>
<script>
export default {
  props: {
    /**
     * Tooltip title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Tooltip table rows
     * eg of row: {color: 'blue', label: 'Fund', value: '102.2'}
     */
    rows: {
      type: Array,
      default: () => [],
    },
    /**
     * Bound of the chart
     * Could be used to calculate the tooltip postion
     */
    chartArea: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    style() {
      return 'top: 50%; left: 50%; transform: translate(-50%, -50%)'
    },
  },
}
</script>
