import { ref, computed } from 'vue'
import { merge } from '../utils/merge'

const translations = ref({})

export function setTranslation(translation, key = 'default') {
  translations.value[key] = merge(translations.value[key] || {}, translation)
}

/**
 * Get parsed query params
 * @return {{}}
 */
function getQueries() {
  return location.search
    .replace('?', '')
    .split('&')
    .reduce((acc, b) => {
      const q = b.split('=')
      acc[q[0]] = q[1] || undefined
      return acc
    }, {})
}

export function useTranslation() {
  const query = getQueries()
  const lang = ref(query.lang || localStorage.LANG || 'en')
  const t = computed(() => {
    // TODO don't rely on $root.app
    const app = $root.app || 'default'
    const current = translations.value[app][lang.value] || {}
    const def = translations.value.default[lang.value] || {}
    return { ...def, ...current }
  })
  const langs = computed(() => {
    const app = $root.app || 'default'
    return Object.keys(translations.value[app])
  })
  return {
    lang,
    translations,
    t,
    langs,
  }
}
