import { ref } from 'vue'

const triggerEnter = point => point.hover()
const triggerLeave = point => point.clearHover()

export default function () {
  const legends = ref([])
  const handleDrawn = chart => {
    /**
     * If it s a PieChart or similar, have to get the values from the points.
     */
    if (chart.component.seriesFamilies === null) {
      legends.value = chart.component.series
        .map(s => s.getPoints())
        .reduce((acc, b) => acc.concat(b), [])
        .map(p => ({
          label: p.argument,
          background: p.getColor(),
          borderColor: p.getColor(),
          data: p.properValue,
          triggerEnter: () => triggerEnter(p),
          triggerLeave: () => triggerLeave(p),
        }))
      return
    }
    legends.value = chart.component.series.map(s => ({
      label: s.name,
      background: s.getColor(),
      borderColor: s.getColor(),
    }))
  }
  return {
    legends,
    handleDrawn,
  }
}
