import * as Plottable from 'plottable'

window.Plottable = Plottable
// TODO Consider using explicit import of Plottable instead of relying on `global.js` `window.Plottable = Plottable` side-effect
// TODO export custom configured Plottable for explicit import in concerned components
export const SCALE_5 = scale => {
  // We want 5 or 6 ticks + we want the 0 to be displayed
  const [min, max] = scale.domain()
  const diff = max - min
  const power = Math.round(Math.log10(diff))
  const base100 = diff / Math.pow(10, power - 1)
  const smooth = x => (x % 5 ? x + 5 - (x % 5) : x) / 5
  const tick = smooth(base100) * Math.pow(10, power - 1)
  const start = Math.ceil(Math.abs(min / tick)) * Math.sign(min)
  const end = Math.ceil(Math.abs(max / tick)) * Math.sign(max)
  return start.upto(end).__.map(d => (d * tick).format())
}
window.Plottable.Plots.Bar._LABEL_PADDING = 4
window.Plottable.Plots.StackedBar._STACKED_BAR_LABEL_PADDING = 4
window.Plottable.Plots.StackedBar._LABEL_PADDING = 0
// Decorate every "on[Event]" function to not trigger when the menu is open
// const after = r => console.log('after', r) || r
// const before = (...args) => console.log('before', args) || args
// Function.wrap = (fn, after = x => x, before = x => x) => (...args) => after(fn(...before(args)))
// window.Plottable.Interactions.__.map((fn, fname) =>
//   fn.prototype.__.keys().__.filter(k => /^on/.test(k)).__.map(k => {
//     const _fn = fn.prototype[k]
//     fn.prototype[k] = function (callback) {
//       if ($root.$route.query.menu === 'on') return this
//       if ($root.$route.query.filters === 'on') return this
//       if ($root.$route.query.period === 'on') return this
//       if ($root.screen.theme) return this
//       _fn.bind(this)(callback)
//       return this
//     }
//   })
// )
// HACK to stop errors
window.Plottable.Plot._scaledAccessor = function (binding) {
  const scale = binding.scale
  const accessor = binding.accessor
  const postScale = binding.postScale
  const scaledAccesor =
    scale == null
      ? accessor
      : function (d, i, ds) {
          return scale.scale(accessor(d, i, ds))
        }
  return scaledAccesor
}
window.Plottable.Drawers.SVGDrawer.prototype.getVisualPrimitives = function () {
  if (this._cachedVisualPrimitivesNodes == null) {
    this._cachedVisualPrimitivesNodes = this._selection ? this._selection.nodes() : this._root.nodes()
  }
  return this._cachedVisualPrimitivesNodes
}
window.Plottable.Utils.Translator.isEventInside = function (component, e) {
  if (!component.root().rootElement()) return false
  return window.Plottable.Utils.DOM.contains(component.root().rootElement().node(), e.target)
}
// HACK END
window.Plottable.Plots.ClusteredBar.prototype._makeInnerScale = function () {
  const innerScale = new window.Plottable.Scales.Category().innerPadding(0.05).outerPadding(0)
  const widthProjector = new window.Plottable.Plot._scaledAccessor(this.attr('width'))
  innerScale.range([0, widthProjector(null, 0, null)])
  innerScale.domain(this.datasets().__.map((d, i) => String(i)))
  return innerScale
}
