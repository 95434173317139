<style>
.block.fullscreen .plot-block {
  max-height: calc(50vh - 70px);
}
.block .content { display: flex; flex: 1; flex-direction: column; justify-content: center}
.block { min-height: fit-content; }
</style>
<template lang="pug">
.block(:class="['block-' + (type || 'custom'), 'block-' + (title || 'no-title'), title || 'no-title', fullscreen && 'fullscreen']")
  slot(name="title" :title="title" :fullscreen="fullscreen" :toggleFullscreen="toggleFullscreen")
    h2(v-if="title && (!/^block_/.test(title) || t[title])")
      div(v-html="title.split(',').length > 1 ? title.split(',').__.map(d => t[d] || d).join(' ').capitalize(true) : t[title] || title")
      button.ghost(aria-label="fullscreen" @click="toggleFullscreen()")
        svg-icon.nx-fullscreen(name="nx-fullscreen")
  //- TODO what is that for ?
  //- .hack-title(style="display: none;" v-else="")
  //-   | {{ (title || type || 'block').replace('pdf', '').replace($root.params.screen, '').titleize() }}
  slot(name="subtitle")
    .subtitle(v-html="t[subtitle] || subtitle" v-if="subtitle")
  slot.content
    plot-block(:type="type" ref="component" :guideline="guideline" @guideline="$emit('guideline', $event)" :tooltip="tooltip" @tooltip="$emit('tooltip', $event)" :enableActions="enableActions")
  slot(name="disclaimer")
    .disclaimer(v-html="t[disclaimer] || disclaimer" v-if="disclaimer")
</template>

<script>
import { provide, computed, onMounted, ref, watch } from 'vue'
// const customEvents = ['mounted', 'rebind', 'plotclick', 'brush', 'tooltip', 'guideline']
export default {
  name: 'block',
  props: ['type', 'title', 'subtitle', 'disclaimer', 'data', 'metadata', 'guideline', 'tooltip', 'enableActions'],
  emits: ['tooltip', 'guideline'],
  // emits: customEvents,
  setup(props, { emit, attrs }) {
    // Provide all props to be injected by children
    // Object.entries(props).__.map(([key, value]) => provide(key, computed(() => value)))
    // Needs to make it a computed to make it reactive but makes it compliacted down the line
    window.attrs = attrs
    Object.keys(attrs).__.map(k => provide(k, attrs[k]))
    Object.keys(props).__.map(k => provide(k, computed(() => props[k])))
    // customEvents.__.map(eventName => provide(eventName, (ev) => emit(eventName, ev)))
    const height = ref()
    const fullscreen = ref(false)
    const toggleFullscreen = () => {
      fullscreen.value = !fullscreen.value
      window.dispatchEvent(new Event('resize'))
    }
    provide('fullscreen', fullscreen)
    // TODO useless here, if its needed put it in plot-block or something
    // onMounted(() => {
    //   if (/plot/.test(props.type) && this.$el.getBoundingClientRect().height > 400) height.value = this.$el.getBoundingClientRect().height - 76 + 'px'
    // })
    return { height, fullscreen, toggleFullscreen }
  },

}
</script>
