<template lang="pug">
.legend
  slot(name="title-comments")
  .flex.flex-row
    .legend-item.flex.flex-row.items-center.py-1(v-for="(legend, i) in legends" :key="legend.label" :class="{'cursor-pointer': !!legend.triggerEnter}" @mouseenter="handleMouseEnter(legend)" @mouseleave="handleMouseLeave(legend)")
      legend-marker.m-1(:color="legend.background" :borderColor="legend.borderColor")
      span.m-px.text-gray-500.text-xs {{legend.label}}
</template>
<script>
import LegendMarker from './legend-marker.vue'

export default {
  components: {
    LegendMarker,
  },
  props: {
    legends: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['enter', 'leave'],
  methods: {
    handleMouseEnter(legend) {
      if (legend.triggerEnter) legend.triggerEnter()
      this.$emit('enter', legend)
    },
    handleMouseLeave(legend) {
      if (legend.triggerLeave) legend.triggerLeave()
      this.$emit('leave', legend)
    },
  },
}
</script>
