<template lang="pug">
svg.svg-icon
  use(:href="base + 'sprite.svg#' + name")
</template>

<script>
/**
 * SvgIcon component - Display an svg icon
 */
export default {
  name: 'SvgIcon',
  props: {
    /**
     * Icon name
     */
    name: {
      type: String,
      default: 'ic_star'
    }
  },
  data() {
    return {
      base: (document.baseURI === window.location.href) ? '/' : document.baseURI
    }
  }
}
</script>
