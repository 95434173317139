<template lang="pug">
.accordion_table
  table
    tbody
      tr(@click='isOpen = !isOpen' class="cursor-pointer")
        td 
          svg-icon(:name="isOpen ? 'ic_minus' : 'ic_plus'" class="font_color")
          svg-icon( v-if="!group" name="ic_minus" class="font_color")
        td
          h2 {{ group }} 
        td
          span.table_lable  {{t.position}}
          .font-bold.text-primary {{ position }} 
        td
          span.table_lable {{t.allocation}}
          .font-bold.text-primary {{ allocation }} 
        td 
          span.table_lable {{t.returns}}
          .font-bold.text-primary {{ returns }} 
        td 
          span.table_lable {{ t.pnl }}
          .font-bold.text-primary {{ pnl }} 
        td 
          span.table_lable {{t.contribution}}
          .font-bold.text-primary {{ contribution }} 
.child_td(v-if="isOpen")
  slot
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'accordion',
  props: {
    group: {
      type: String,
    },
    allocation: {
      type: String,
    },
    position: {
      type: String,
    },
    returns: {
      type: String,
    },
    pnl: {
      type: String,
    },
    contribution: {
      type: String,
    },
  },
  setup() {
    const isOpen = ref(false)
    return { isOpen }
  },
}
</script>

<style>
tbody tr:nth-child(odd) {
  background: transparent !important;
}
td,
tr,
th {
  box-shadow: none !important;
}

.accordion_table table {
  background: white !important;
  border-radius: 4px !important;
  box-shadow: var(--box-shadow);
  margin: 6px 0;
  padding: 2em;
}
.accordion_table table:hover {
  background: white !important;
  padding: 2em;
}
.accordion_table td:nth-child(2) {
  max-width: 100px;
  width: 40%;
  text-align: left !important;
  box-shadow: none !important;
}
.accordion_table td {
  max-width: 100px;
  width: 10%;
  padding: 4px 10px;
  border-radius: 0 0 4px 4px !important;
  margin: 0px;
  line-height: 1.2;
}

@media screen and (max-width: 300px) {
  .accordion_table td:nth-child(2) {
    max-width: 80px;
    width: 10%;
    text-align: left !important;
  }
}
@media screen and (max-width: 300px) {
  .accordion_table td {
    max-width: 80px;
    width: 10%;
    text-align: left !important;
    padding: 4px !important;
  }
}
.child_td {
  background: rgb(5 111 129 / 35%);
}
.child_td table {
  background: white;
  padding: 0.5em;
  margin-left: 0 !important;
  margin-top: -1px;
  margin-bottom: 0px;
  border-radius: 0px !important;
  text-transform: uppercase;
  font-size: 13px;
}
.child_td table svg {
  width: 14px !important;
}

.table_lable {
  color: grey;
  display: inline-block;
  text-transform: capitalize;
  font-size: 10px;
}
.child_td table .table_lable {
  display: none;
}
body > main > section > div.flex.flex-col > div.child_td.m-2 > div:nth-child(1) tr {
  text-align: right;
}
</style>
