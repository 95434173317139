<template lang="pug">
nav.nav-top
  nav-top-calendar
  nav-top-tabs
</template>

<script>
export default {
  "props":["type"],
  computed: {
      title() {
        return this.t[this.$route.params.userflow] || this.t[this.$route.params.screen || 'slash']
      },
  }
}
</script>
