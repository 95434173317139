import { ref } from 'vue'
import { allColors, genColors } from '../../utils'

export default function () {
  const palette = ref([])
  palette.value = allColors()

  return {
    palette,
  }
}
