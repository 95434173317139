<template lang="pug">
.relative.chart
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  dx-chart(
    :data-source="data"
    @point-hover-changed="handleTooltip"
    @series-hover-changed="handleSeriesHover"
    @customize-point="options.customizePoint"
    @drawn="handleDrawn"
  )
    dx-common-series-settings(type="line" :argumentField="options.labelKey")
      dx-point(hoverMode="allArgumentPoints" :size="8")
    dx-series(
      v-for="(dataset, i) in options.datasets"
      :name="dataset.label"
      :key="dataset.key"
      :value-field="dataset.key"
      :color="dataset.backgroundColor || getColor(i)"
      hoverMode="allArgumentPoints")
    dx-margin(:bottom="20")
    dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels")
      dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
    dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
    dx-legend(:visible="false")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
} from 'devextreme-vue/chart'
import LegendMarker from '../legend/legend-marker.vue'
import useTooltip from './composables/useTooltip'
import { toRefs } from 'vue'
import useColors from './composables/useColors'
import useSeriesHover from './composables/useSeriesHover'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    LegendMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useTooltip(options, emit)
    const { getColor } = useColors()
    const { handleSeriesHover } = useSeriesHover(emit)
    const { legends, handleDrawn } = useCustomLegends()

    return {
      tooltip,
      handleTooltip,
      getColor,
      handleSeriesHover,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
