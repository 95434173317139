<template lang="pug">
.relative.chart
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  dx-chart(
      :data-source="data"
      @point-hover-changed="handleTooltip"
      @series-hover-changed="handleSeriesHover"
      @customize-point="options.customizePoint"
      @drawn="handleDrawn"
    )
      dx-common-series-settings(type="candlestick" :argumentField="'date'")
      dx-series(
        :name="options.dataset.label"
        open-value-field="o"
        close-value-field="c"
        high-value-field="h"
        low-value-field="l"
        :color="options.dataset.backgroundColor || getColor(0)"
        )
      dx-margin(:bottom="20")
      dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels" argument-type="datetime" :workdays-only="true")
        dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
        dx-label(format="shortDate")
      dx-value-axis
        dx-label
          dx-format(:precision="0" type="currency")
      dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
      dx-legend(:visible="false")
      template(#markerTemplate="{ data }")
        legend-marker(:color="data.marker.fill")
  slot(name="tooltip" :tooltip="tooltip")
    table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
  DxValueAxis,
  DxFormat,
} from 'devextreme-vue/chart'
import RoundedMarker from './dx-rounded-marker.vue'
import { toRefs } from 'vue'
import useColors from './composables/useColors'
import useSeriesHover from './composables/useSeriesHover'
import useCandlestickTooltip from './composables/useCandlestickTooltip'
import LegendMarker from '../legend/legend-marker.vue'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    DxValueAxis,
    DxFormat,
    LegendMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useCandlestickTooltip(options, emit)
    const { getColor } = useColors()
    const { handleSeriesHover } = useSeriesHover(emit)
    const { legends, handleDrawn } = useCustomLegends()

    return {
      tooltip,
      handleTooltip,
      getColor,
      handleSeriesHover,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
