const TRIGGERABLE = ['pie', 'doughnut']
const isTriggerable = chart => TRIGGERABLE.includes(chart.config.type)

/**
 * Set active elements of the chart
 * @param chart
 * @param i
 */
const triggerEnter = (chart, i) => {
  chart.tooltip.setActiveElements([{ datasetIndex: 0, index: i }])
  chart.setActiveElements([{ datasetIndex: 0, index: i }])
  chart.render()
}

/**
 * clear active elements
 * @param chart
 */
const triggerLeave = chart => {
  chart.tooltip.setActiveElements([])
  chart.setActiveElements([])
  chart.render()
}

export const customLegendPlugin = {
  id: 'customLegend',
  afterUpdate(chart, args, options) {
    if (!chart.options.plugins.customLegend?.legends) return

    const { legends } = chart.options.plugins.customLegend
    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    legends.value = items.map(l => ({
      background: l.fillStyle,
      borderColor: l.strokeStyle,
      label: l.text,
      data: chart.data.datasets[0]?.data[l.index],
      triggerEnter: isTriggerable(chart) ? () => triggerEnter(chart, l.index) : undefined,
      triggerLeave: isTriggerable(chart) ? () => triggerLeave(chart, l.index) : undefined,
    }))
  },
}
