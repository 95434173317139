<template lang="pug">
button(class="uppercase" style="background: var(--primary);color: white;font-size: 80%;font-weight: bold;width: 200px;" @click="overriden = !overriden") Show {{ overriden ? 'All' : 'Only Overriden' }}
table
  thead(class="uppercase" style="background: var(--primary);color: white;")
    th KEY
    th(v-for="lang in langs") {{ lang }}
  template(v-for="key in keys")
    tr(v-if="!overriden || $root.db?.config?.translation?.en?.[key] || $root.db?.config?.translation?.fr?.[key]")
      td {{ key }}
      td(style="padding: 8px;" v-for="lang in langs")
        div(style="position: absolute;margin: -6px;padding: 0 4px;background: white;border: var(--border);border-radius: 3px;font-size: 10px;" title="Original Value") {{ translations[lang][key] }}
        input(style="background: white;padding: 8px 16px;" :value="$root.db?.config?.translation?.[lang]?.[key]" @change="set(`config.translation.${lang}.${key}`, $event.target.value || null)")
</template>

<script>
export default {
  icon: 'ic_baseline_language',
  roles: ['admin'],
  secured: true,
}
</script>
<script setup>
import { ref } from 'vue'
const translations = $root.translations.cedrus_original
const langs = translations.__.keys()
const keys = translations.en.__.keys().sort(v => v)
const overriden = ref(true)
</script>
