<template lang="pug">
.relative.chart
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  dx-chart(
    :data-source="computedData"
    @point-hover-changed="handleTooltip"
    @series-hover-changed="handleSeriesHover"
    @customize-point="options.customizePoint"
    @drawn="handleDrawn"
  )
    dx-common-series-settings(type="scatter")
      dx-point(:size="8")
    dx-series(
      v-for="(dataset, i) in options.datasets"
      :name="dataset.label"
      :key="dataset.key"
      :argumentField="`${dataset.key}_x`"
      :value-field="`${dataset.key}_y`"
      :color="dataset.backgroundColor || getColor(i)"
      hoverMode="allArgumentPoints")
    dx-margin(:bottom="20")
    dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels")
      dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
    dx-crosshair(:enabled="true" :horizontalLine="false" dashStyle="dash" color="#555555")
    dx-legend(:visible="false")
slot(name="tooltip" :tooltip="tooltip")
  table-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows')
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxTickInterval,
  DxCrosshair,
  DxLabel,
  DxPoint,
  DxFont,
} from 'devextreme-vue/chart'
import LegendMarker from '../legend/legend-marker.vue'
import { computed, toRefs } from 'vue'
import useColors from './composables/useColors'
import useSeriesHover from './composables/useSeriesHover'
import useXYTooltip from './composables/useXYTooltip'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxTickInterval,
    DxCrosshair,
    DxLabel,
    DxPoint,
    DxFont,
    LegendMarker,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useXYTooltip(options, emit)
    const { getColor } = useColors()
    const { handleSeriesHover } = useSeriesHover(emit)
    const { legends, handleDrawn } = useCustomLegends()


    /**
     * Trnasform {serie1: [{x, y}], serie2..} to [{serie1_x, serie1_y, serie2_x, serie2_y}...]
     * @type {ComputedRef<void>}
     */
    const computedData = computed(() => {
      const max = Math.max(...Object.values(data.value).map(v => v.length))
      const res = []
      const datasets = options.value.datasets.map(d => d.key)
      for (let i = 0; i < max; i++) {
        res.push(
          datasets
            .map(k => {
              if (i >= data.value[k].length) return {}
              return {
                [`${k}_x`]: data.value[k][i].x,
                [`${k}_y`]: data.value[k][i].y,
              }
            })
            .reduce((o, b) => {
              Object.entries(b).forEach(([k, v]) => (o[k] = v))
              return o
            }, {}),
        )
      }
      return res
    })
    return {
      tooltip,
      handleTooltip,
      getColor,
      handleSeriesHover,
      computedData,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
