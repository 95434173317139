import { componentsFromGlobRecord, directivesFromGlobRecord, pagesFromGlobRecord, translationFromGlobRecord } from '@100-m/hauru/src/utils/platformBuilder'
import config from './index.yml'
/**
 * Base app with sync components
 */
export default {
  name: 'cedrus',
  pages: pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages'),
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  directives: directivesFromGlobRecord(import.meta.globEager('./directives/**/*.js')),
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
  config,
}
