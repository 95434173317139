<template lang="pug">
slot(name="tooltip" :tooltip="computedTooltip")
  .tooltip-line(v-if="computedTooltip && computedTooltip.x")
    div {{ t[computedTooltip.label] || computedTooltip.label || t[computedTooltip.x] || computedTooltip.x }}
    div(v-html="unit(format((metadata && metadata.format) || '.2%')(+computedTooltip.y))")
  .tooltip-line(v-if='computedTooltip && computedTooltip.date && computedTooltip.y')
    div {{ new Date(computedTooltip.date).format('day, mon, year', $root.lang).titleize() }}
    div(v-html="unit(format((metadata && metadata.format) || '.2f')(+computedTooltip.y))")
  .tooltip-table(v-if="computedTooltip && computedTooltip.date && !computedTooltip.y")
    div
      | {{ '' + new Date(computedTooltip.date) === 'Invalid Date' || (metadata && metadata.xAxisNumeric) ? computedTooltip.date : new Date(computedTooltip.date).format('day, mon, year', $root.lang).titleize() }}
    div
      div.py-4(v-for="v, k in computedTooltip.__.filter((v,k) => k !== 'date')" :key="k")
        div {{ t[k] || k }}
        div(v-html="unit(format((metadata && metadata.format) || '.2f')(+v))")
.row.content
  component.dx-plot(:is="type" :data="data" :metadata="metadata" ref="component" @tooltip="onTooltip" @onSeriesHover="onSeriesHover")
  //- slot(name="fullscreen")
  //-   //- TODO this is unreadable
  //-   board.data(v-if="fullscreen" :data="data.__.map((v, k) => typeof v === 'object' ? Object.assign({ 'sum': v.__.v().sum() }, v) : v).__.map((v, k) => Object.assign({ [title.includes(',') ? title.split(',').last() : 'title']: t[k] || k }, typeof v === 'object' ? v : { [title.split(',').first()]: v })).__.v().sort(d => new Date(data.__.keys().first()).toDateString() !== 'Invalid date' ? d :d[fullscreenColumns.first()])" :metadata="{ columns: typeof data.__.v().first() === 'object' ? fullscreenColumns.concat(['sum']) : fullscreenColumns, sort: '-' + fullscreenColumns.last() }")
</template>
<script>
import { ref, computed, inject, reactive, onMounted } from 'vue'
export default {
  props: {
    data: {
      type: [Object, Array],
      default: null,
    },
    metadata: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
    guideline: {
      type: Date,
      default: () => null,
    },
    enableActions: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Object,
      default: () => null,
    }
  },
  emits: ['mounted', 'rebind', 'plotclick', 'onSeriesHover', 'tooltip', 'guideline'],
  setup(props, { emit, attrs }) {
    const component = ref(null)
    // const data = inject('data', computed(() => props.data))
    const getEmitter = eventName => {
      const emitter = inject('on' + eventName.capitalize(), null)
      if (!emitter) return (ev) => emit(eventName, ev)
      return emitter
    }

    // const metadata = inject('metadata', computed(() => props.metadata))
    const tooltipData = ref()
    const computedTooltip = inject('computedTooltip', computed(() => props.tooltip || tooltipData.value))
    const _onTooltip = getEmitter('tooltip')
    const onTooltip = (ev) => {
      _onTooltip(ev)
      tooltipData.value = ev
    }
    const onSeriesHover = getEmitter('onSeriesHover')
    // const onSeriesHover = (ev) => {}

    const components = ref()
    const _onRebind = getEmitter('rebind')
    const onRebind = (ev) => {
      _onRebind(ev)
      components.value = ev
    }
    // const _onPlotclick = (datum, entities) => {
    //   const dimension = metadata?.value?.dimension
    //   if (!dimension) return
    //   if (!entities.length) {
    //     const filter = components.value.plot
    //       .datasets()
    //       .__.map(d => d.data())
    //       .flat(Infinity)
    //       .__.filter(d => d.x === datum.x)
    //       .__.map('label')
    //       .join('|')
    //     return update_filter(dimension, filter)
    //   }
    //   update_filter(dimension, datum.label || datum.x)
    // }
    // const onPlotclick = inject('onPlotclick', _onPlotclick)
    // debugger
    // TODO we should not need this
    const title = inject('title', props.title)
    // const height = ref('auto')
    // const resize = () => {
    //   const plotElement = component?.value?.$el?.querySelector('.component')
    //   if (!plotElement) return
    //   height.value = plotElement.style.height
    // }
    onMounted(() => {
      resize()
      const plotElement = component?.value?.$el?.querySelector('.component')
      if (!plotElement) return
      new ResizeObserver(resize).observe(plotElement)
    })
    const fullscreen = inject('fullscreen')
    const fullscreenColumns = computed(() => {
      return data.value.__.map((v, k) => Object.assign({ [title.value.includes(',') ? title.value.split(',').last() : 'title']: $root.t[k] || k }, typeof v === 'object' ? v : { [title.value.split(',').first()]: v })).__.v().first().__.keys()
    })

    return {
      computedTooltip,
      // metadata,
      // data,
      tooltipData,
      onTooltip,
      onRebind,
      components,
      log: console.log,
      title,
      fullscreen,
      fullscreenColumns,
      component,
      onSeriesHover,
    }
  },
}
</script>
<style scoped>
.dx-plot {
  flex: 1;
  height: auto;
}
.content {
  height: 100%;
}
.plot-block {
  min-height: 20vh;
}
.legend {
  margin: -4px 4px 12px;
}
/* .board.data {
  position: relative;
  top: 64px;
  bottom: 40px;
  overflow: auto;
  width: 100%;
  flex: 0 0 15em;
  padding-left: 55px;
  min-width: 280px;
} */
</style>
