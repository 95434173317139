import firebase from 'firebase/app'
import 'firebase/database'
import { ref, watch, getCurrentInstance, onUnmounted } from 'vue'

let database, rootRef, publicRef
function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: `${import.meta.env.VITE_FIREBASE_NAME}.firebaseapp.com`,
      databaseURL: `https://${import.meta.env.VITE_FIREBASE_NAME}.firebaseio.com`,
    })
  }
  database = firebase?.database?.()
  rootRef = database.ref(import.meta.env.VITE_FIREBASE_ROOT || import.meta.env.VITE_PROJECT)
  publicRef = rootRef.child('public')
  return true
}
export const useRTDB = () => {
  if (!import.meta.env.VITE_FIREBASE_API_KEY || !import.meta.env.VITE_FIREBASE_NAME) return
  initFirebase()
  const data = ref(undefined)
  let remote = false
  const onupdate = snapshot => {
    data.value = snapshot.val()
    remote = true
  }
  publicRef.on('value', onupdate)
  if (getCurrentInstance()) onUnmounted(() => publicRef.off('value', onupdate))
  // const onwatch = async () => {
  //   if (remote) return (remote = false)
  //   if (data.value == null) return await publicRef.remove()
  //   try {
  //     return await publicRef.set(data.value)
  //   } catch(e) {
  //     return await publicRef.update(data.value)
  //   }
  // }
  // watch(data, onwatch, { deep: true })
  return {
    db: data,
  }
}

export function get(path) {
  console.log('get', path)
  return path.split('.').__.reduce((acc, p) => acc?.[p], $root.db)
}
export function set(path, value) {
  console.log('set', path, value)
  return path
    .split('.')
    .__.reduce((acc, p) => (!p ? acc : acc.child(p)), publicRef)
    .set(value)
}
export function update(path, value) {
  console.log('update', path, value)
  return path
    .split('.')
    .__.reduce((acc, p) => (!p ? acc : acc.child(p)), publicRef)
    .update(value)
}
