<template lang="pug">
.relative.chart
  slot(name="legend" :legends="legends")
    legends(:legends="legends")
  dx-polar-chart(
    :data-source="data"
    @point-hover-changed="handleTooltip"
    @series-hover-changed="handleSeriesHover"
    :customize-point="options.customizePoint"
    :palette='palette'
    :use-spider-web="true"
    @drawn="handleDrawn"
  )
    dx-common-series-settings(type="line")
      dx-point(hoverMode="allArgumentPoints" :size="8")
    dx-series(
      v-for="(dataset, i) in options.datasets"
      :name="dataset.label"
      :key="dataset.key"
      :argument-field="options.labelKey"
      :value-field="dataset.key"
      hoverMode="allArgumentPoints")
    dx-margin(:bottom="20")
      dx-margin(:bottom="20")
    dx-value-axis
      dx-label(:customizeText="customizeValue")
    dx-argument-axis(:value-margins-enabled="false" discrete-axis-division-mode="crossLabels")
      dx-grid(:visible="true" color="#D9D9D9" :opacity="0.7" dash-style="dash")
    dx-legend(:visible="false")
  slot(name="tooltip" :tooltip="tooltip")
    donut-tooltip(v-if="tooltip.shown" :title='tooltip.title' :rows='tooltip.rows' :chartArea='tooltip.chartArea')
</template>
<script>
import DxPolarChart, {
  DxSeries,
  DxCommonSeriesSettings,
  DxPoint,
  DxMargin,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxLabel,
  DxFont,
  DxArgumentAxis,
  DxGrid,
  DxValueAxis,
} from 'devextreme-vue/polar-chart'
import LegendMarker from '../legend/legend-marker.vue'
import useTooltip from './composables/useTooltip'
import { toRefs } from 'vue'
import useSeriesHover from './composables/useSeriesHover'
import useCustomizeText from './composables/useCustomizeText'
import usePalette from './composables/usePalette'
import useCustomLegends from './composables/useCustomLegends'

export default {
  components: {
    DxPolarChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxMargin,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxLabel,
    DxFont,
    DxPoint,
    DxArgumentAxis,
    DxGrid,
    DxValueAxis,
    LegendMarker,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['tooltip', 'seriesHover'],
  setup(props, { emit }) {
    const { data, options } = toRefs(props)
    const { tooltip, handleTooltip } = useTooltip(options, emit)
    const { handleSeriesHover } = useSeriesHover(emit)
    const { palette } = usePalette()
    const { customizeValue } = useCustomizeText(options)
    const { legends, handleDrawn } = useCustomLegends()

    return {
      tooltip,
      handleTooltip,
      palette,
      handleSeriesHover,
      customizeValue,
      legends,
      handleDrawn,
    }
  },
}
</script>
<style scoped>
.chart {
  height: 100%;
}
</style>
