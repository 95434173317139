<template lang="pug">
template(v-if="$root.db.config")
  page-header(
    :page="t[$root.screen.name] || $root.screen.name"
    :fund="t[$root.userflow.name] || $root.userflow.name"
    :benchmark="t[$root.userflow.benchmark] || $root.userflow.benchmark"
    :email="t[$root.userflow.contact_email] || $root.userflow.contact_email")

  .row.graph_box
    .block
      kpi-box(:label="t.performance" :value="format('.2%')(getPerformance)" :benchmark_label="t.benchmark" :benchmark_value="format('.2%')(getBenchmark)")
    .block
      kpi-box(:label="t.financial_product" :value="format('.3s'+$root.userflow.currency)(gainLoss)" :benchmark_label="t.aum" :benchmark_value="format( '.3s'+$root.userflow.currency)(getAum)")
    .block
      kpi-box(:label="t.drawdown" :value="format('.2%')(getDrawdown)" :benchmark_label="getRecoveryPeriod" :optional_label="t.recovery" :optional_value="getRecoveryDuration" )
    .block
      kpi-box(:label="t.volatility" :value="format('.2%')(getVolatility_fund)" :benchmark_label="t.volatility_benchmark" :benchmark_value="format('.2%')(getVolatility_benchmark)" :optional_label="t.var_x" :optional_value="format('.2%')(getVarX)")
    .block
      kpi-box(:label="t.min" :value="format('.2%')(kpiMin(performance_historical, r => r.fund))" :benchmark_label="t.max" :benchmark_value="format('.2%')(kpiMax(performance_historical, r => r.fund))" :optional_label="t.avg" :optional_value="format('.2%')(kpiAvg(performance_historical, r => r.fund))")

  .row
    .block_canvas
      h2 {{t.returns}}
      cjs-bar-chart(:data="rolling_returns" :options="returnsOptions")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatPercentRows(tooltip.rows)")

    .block_canvas
      h2 {{t.performance}}
      cjs-line-chart(:data="performance_historical" :options="performanceKpiOptions")
        template(v-slot:legend="{legends}")
          .row
            .column.performance-legend
              legends(:legends="formatPerformanceLegends(legends)")
            .column.performance-legend-kpi
              valued-legends(:legends="formatPerformanceKpiLegends(legends, performance_historical)")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatPerformancePercentRows(tooltip.rows)" )

  .row
    .block_canvas
      h2 {{t.aum}}
      cjs-timeseries-chart(:data="aum_evolution" :options="aumOptions")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")

    .block_canvas
      .row.flex-nowrap.justify-between
        h2 {{t.allocation}} {{ t.at}} {{$root.domain[1]}}
        select.border-none.text-center.w-20(v-if="$root.userflow.currency=='EUR'" v-model="currency")
          option(value="euros") €
          option(value="percentage") %
        select.border-none.text-center.w-20(v-else v-model="currency")
          option(value="USD") $
          option(value="percentage") %
      .col
        cjs-donut-chart(v-if="currency=='euros' ||currency== 'USD'" :data="pie_allocation" :options="allocationsOptions.type" @series-click="update_filter('type', $root.t.__.find(v => v === $event) || $event)")
          template(v-slot:legend="{legends}")
            valued-legends(:legends="formatNumberLegends(legends)")
          template(v-slot:tooltip="{tooltip}")
            donut-tooltip(v-if="!tooltip.shown" :title="tooltip.title" :rows="showNumberAum(tooltip.rows)" )
            donut-tooltip(v-else :title="tooltip.title" :rows="formatMillionRows(tooltip.rows)" :chartArea="tooltip.chartArea")

        cjs-donut-chart(v-else :data="pie_allocation" :options="allocationsOptions.type" @series-click="update_filter('type', $root.t.__.find(v => v === $event) || $event)")
          template(v-slot:legend="{legends}")
            valued-legends(:legends="formatPercentLegends(legends)")
          template(v-slot:tooltip="{tooltip}")
            donut-tooltip(v-if="!tooltip.shown" :title="tooltip.title" :rows="showPercentAum(tooltip.rows)" )
            donut-tooltip(v-else :title="tooltip.title" :rows="formatPercentDonutChart(tooltip.rows)" :chartArea="tooltip.chartArea")

  .row
    .block_canvas
      .row
        h2
          a(@click.prevent="handleClickCummulated" v-html="showCumulatedGraph ? t.cumulated_liquidity : t.not_cumulated_liquidity")
          .right_push {{ t.at }} {{ $root.domain[1] }}
      cjs-bar-chart(v-show="showCumulatedGraph" :data="cumulated_liquidy" :options="cumulatedOptions")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")
    
      cjs-bar-chart(v-show="showUncumulatedGraph" :data="uncumulated_liquidy" :options="cumulatedOptions")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")

    .block_canvas
      h2 {{t.contribution}}
      cjs-bar-chart(:data="contribution_graph" :options="contributionOptions")
        template(v-slot:tooltip="{tooltip}")
          table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatPercentRows(tooltip.rows)")
</template>

<script>
import { ref } from 'vue'
import useKpis from '../../composables/useKpis'
import usePerformance from '../../composables/usePerformance'
import * as common from '../../composables/common'

export default {
  icon: 'ic_timeline',
  datasets: ['allocation', 'contribution', 'performance', 'mapping', 'trades'],
  setup() {
    var currency = $root.userflow.currency == 'EUR' ? ref('euros') : ref('USD')
    const showCumulatedGraph = ref(true)
    const showUncumulatedGraph = ref(false)
    function handleClickCummulated() {
      showCumulatedGraph.value = !showCumulatedGraph.value
      showUncumulatedGraph.value = !showUncumulatedGraph.value
    }
    return {
      currency,
      ...common,
      ...useKpis(),
      ...usePerformance(),
      showCumulatedGraph,
      showUncumulatedGraph,
      handleClickCummulated,
    }
  },
}
</script>

<style scoped></style>
