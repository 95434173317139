<template lang="pug">
footer.mt-8.text-secondary
  .row.mt-8.border-t-2
    .mt-4.flex-1
      .row.justify-between
        .column
          span.text-gray-400 {{ t.title }}
          span.text-gray-400 {{ t.sas }}
          span.text-gray-400 {{ t.serit }}
          span.text-gray-400 {{ t.adresse_paris }}
          span.text-gray-400 {{ t.adresse_bordeaux }}
        .column.justify-center
          span.text-xl.font-bold.text-primary {{ pageNumber }}
 
  
</template>

<script>
export default {
  props: {
    pageNumber: {
      type: Number,
    },
  },
}
</script>

<style scoped>
footer {
  display: block;
  width: 92%;
  position: absolute;
  bottom: 15px;
}
</style>
