import { ref } from 'vue'

/**
 * Tooltip feature composable for chartjs components
 * @return {{tooltipOptions: {external: function(*): void, enabled: boolean}, tooltip: ToRef<{shown: boolean, title: string, rows: []}>}}
 */
export default function () {
  const tooltip = ref({
    shown: false,
    title: '',
    titleColor: null,
    rows: [],
  })
  const tooltipOptions = {
    enabled: false,
    external: context => {
      tooltip.value.shown = context.tooltip.opacity !== 0
      tooltip.value.title = context.tooltip.dataPoints[0].dataset.label
      tooltip.value.titleColor = context.tooltip.dataPoints[0].dataset.backgroundColor
      const dp = context.tooltip.dataPoints[0]
      tooltip.value.rows = ['x', 'y', 'r']
        .filter(k => dp.raw[k])
        .map(k => ({
          value: dp.raw[k],
          label: k,
          color: null,
          borderColor: null,
        }))
    },
  }
  return {
    tooltip,
    tooltipOptions,
  }
}
